import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import useFetch from '../../../hooks/use_fetch';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../../utils/constants';

import Loading from '../../../components/loading';
import Footer from '../../../components/footer';

import './my_plans.scss';

export default function MyPlans() {

    const user = JSON.parse(localStorage.getItem('uli_acr'));

    const purchases = useFetch(URL_API_AURA + '/Purchases?username=' + user.uli + '&clientSecret=' + FINAL_KEY_API_AURA, null);
    
    const [detalleCompraId, setDetalleCompraId] = useState(0);
    const [planName, setPlanName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [open, setOpen] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        border: '1px solid grey',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const handleOpen = (pDetalleCompraId, pPlanName) => {
        setDetalleCompraId(pDetalleCompraId);
        setPlanName(pPlanName);
        setOpen(true);
    };
    
    const handleClose = () => {
        setDetalleCompraId(0);
        setPlanName(null);
        setOpen(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setOpen(false);
        setLoading(true);

        try {
            const res = await fetch(URL_API_AURA + '/UnassignPlan?detalleCompraId=' + detalleCompraId + '&clientSecret=' + FINAL_KEY_API_AURA,
                {
                    method: 'POST'
                }
            );
            
            if(res.status != 200)
            {
                setError('Se ha producido un error al inactivar/desasignar el plan');
            }          
        }
        catch (e)
        {
            setError('Se ha producido un error al intentar inactivar/desasignar el plan');
        }

        setLoading(false);
    }

    if(purchases.loading || !purchases.result)
    {
        return <Loading/>;
    }

    return (
        <>  
            <ModalQuestion open={open} handleClose={handleClose} onSubmit={onSubmit} planName={planName} style={style} />

            <div className='main_plans'>
                <div className='main_plans-card'>
                    <h3>Gestionar Mis Planes</h3>

                    {
                        loading == true
                        ?   <Loading/>
                        :   <div className='main_plans-card__content'>
                                <div className='main_plans-card__content-header-plan'>
                                    <div className='main_plans-card__content-header-plan__col'>                             
                                    </div>
                                    <div className='main_plans-card__content-header-plan__col'>
                                        Id
                                    </div>
                                    <div className='main_plans-card__content-header-plan__col'>
                                        Plan
                                    </div>
                                    <div className='main_plans-card__content-header-plan__col'>
                                        Disponible Desde
                                    </div>
                                    <div className='main_plans-card__content-header-plan__col'>
                                        Fecha Expiración
                                    </div>
                                    <div className='main_plans-card__content-header-plan__col'>
                                        Estado
                                    </div>
                                    <div className='main_plans-card__content-header-plan__col'>
                                        Fecha Asignación
                                    </div>
                                    <div className='main_plans-card__content-header-plan__col'>                              
                                    </div>
                                </div>
                                
                                <div className='main_plans-card__content-plan'>                      
                                    { 
                                        !purchases.result
                                            ? <p>No se encontraron resultados</p>
                                            : purchases.result.map((item, index) => (
                                                <PlanRows key={index} purchase={item} handleOpen={handleOpen} />
                                            ))
                                    }
                                </div>
                            </div>
                    }                                
                </div>
            </div>

            <Footer/>
        </>
        
    );
}

function ModalQuestion(props) {

    const { open, handleClose, onSubmit, planName, style } = props;

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 350 }}>
                <h2 id="parent-modal-title">Desasignar: <b>{planName}</b></h2>
                <p id="parent-modal-description">
                    Desea desasignar el plan?
                </p>
                <Button className='modal-button' onClick={onSubmit}>Sí</Button>
                <Button className='modal-button' onClick={handleClose}>No</Button>
                </Box>
            </Modal>
        </div> 
    );
}

function PlanRows(props) {

    const { purchase, handleOpen } = props;

    return (
        <div className='main_plans-card__content-plan__row'>
            <div className='main_plans-card__content-plan__row-col'>
                {       
                    purchase.activo == true
                    ?   <Button onClick={() => handleOpen(purchase.id, purchase.plan)}>Desasignar</Button>
                    :   <Link to={'/Gestion/' + purchase.id + '/' + purchase.plan}>
                            <Button>Gestionar</Button>
                        </Link>
                }
            </div>
            <div className='main_plans-card__content-plan__row-col'>
                {purchase.id}
            </div>
            <div className='main_plans-card__content-plan__row-col'>
                {purchase.plan}
            </div>
            <div className='main_plans-card__content-plan__row-col'>
                {new Intl.DateTimeFormat('es-CR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(Date.parse(purchase.compra.fechaCompra)).setHours(new Date(Date.parse(purchase.compra.fechaCompra)).getHours() - 6))}
            </div>
            <div className='main_plans-card__content-plan__row-col'>
                {new Intl.DateTimeFormat('es-CR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(Date.parse(purchase.compra.proximoPago)).setHours(new Date(Date.parse(purchase.compra.proximoPago)).getHours() - 6))}
            </div>
            <div className='main_plans-card__content-plan__row-col'>
                {
                    purchase.activo == true
                    ? 'Asignado/Activo'
                    : 'Sin asignar/Inactivo'
                }
            </div>
            <div className='main_plans-card__content-plan__row-col'>
                {
                    purchase.activo == true
                    ? new Intl.DateTimeFormat('es-CR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(Date.parse(purchase.fechaActivo)).setHours(new Date(Date.parse(purchase.fechaActivo)).getHours() - 6))
                    : 'N/A'
                }
            </div>  
            <div className='main_plans-card__content-plan__row-col'>
                <Link to={'/Plan/' + purchase.planId}>
                    <Button>
                        Ver Coberturas
                    </Button>
                </Link>
            </div>  
        </div>
    );
}