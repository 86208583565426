import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { STORAGE_PRODUCTS_CART } from '../../utils/constants';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../utils/constants';
import { KEY_API_BAC, Merchant, Terminal, URL_API, URL_WIDGET } from '../../utils/constants';
import { countDuplicatesItemArray, removeArrayDuplicates } from '../../utils/arrayFunc';
import { encryptedString, base64encode, initializeKey } from '../../utils/payment/rsa';

import Loading from '../../components/loading';
import Processing from '../../components/processing';
import Successful from '../../components/payment/successful';

import './payment.scss';

export default function Payment(props) {

    const { productsCart, getProductsCart, products } = props;

    //#region for cart data
        const [singleProductsCart, setSingleProductsCart] = useState([]);
        const [cartSubTotalPrice, setCartSubTotalPrice] = useState(0);
        const [cartIvaPrice, setCartIvaPrice] = useState(0);
        const [cartTotalPrice, setCartTotalPrice] = useState(0);
    //#endregion for cart data

    const [termsConditions, setTermsConditions] = useState(false);

    //#region for electronic bill
        const [requiredBill, setRequiredBill] = useState(false);
        const [typeId, setTypeId] = useState(1);
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [phone, setPhone] = useState('');

        const [billLoading, setBillLoading] = useState(false);
        const [billError, setBillError] = useState(null);
    //#endregion for electronic bill

    const [loading, setLoading] = useState(false);
    const [messagePayment, setMessagePayment] = useState('');
    const [successfulPayment, setSuccessfulPayment] = useState(false);

    var validateCreditCardLoadInterval = null;

    useEffect(() => {
        const allProductsId = removeArrayDuplicates(productsCart);
        setSingleProductsCart(allProductsId)
    }, [productsCart]);

    useEffect(() => {
        const productData = [];
        let totalPrice = 0;

        const allProductsId = removeArrayDuplicates(productsCart);
        
        allProductsId.forEach(productId => {
            const quantity = countDuplicatesItemArray(productId, productsCart);

            const productValue = {
                id: productId,
                quantity: quantity
            };

            productData.push(productValue);
        });

        if(!products.loading && products.result) {
            products.result.forEach(product => {
                productData.forEach(item => {
                    if(product.plan.id == item.id) {
                        const totalValue = product.plan.costo * item.quantity;
                        totalPrice = totalPrice + totalValue;
                    }                   
                });
            });
        }

        setCartSubTotalPrice(totalPrice);
        setCartIvaPrice(totalPrice * 0.13);
        setCartTotalPrice(totalPrice + (totalPrice * 0.13));
    }, [productsCart, products]);

    function termsConditionsChangeValue() {
        if(termsConditions == true)
        {
            setTermsConditions(false);
        }
        else
        {
            setTermsConditions(true);
        }
    }
    
    function requiredBillChangeValue() {
        if(requiredBill == true)
        {
            setRequiredBill(false);
        }
        else
        {
            getAccountInformation();
            setRequiredBill(true);
        }
    }

    async function getAccountInformation() {
        setBillLoading(true);

        const user = JSON.parse(localStorage.getItem('uli_acr'));

        try {
            const res = await fetch(URL_API_AURA + '/AccountInformation?username=' + user.uli + '&clientSecret=' + FINAL_KEY_API_AURA, null);
            const json = await res.json();
            // setBillResult(json);
            
            setId(json.identificacion);
            setName(json.primerNombre + ' ' + json.segundoNombre + ' ' + json.primerApellido + ' ' + json.segundoApellido);
            setEmail(json.email);
            setPhone(json.telefonoDomicilio);

            setBillLoading(false);
        }
        catch (e)
        {
            setBillError(e);
            setBillLoading(false);
        }
        finally
        {
            setBillLoading(false); 
        }
    }

    const emptyCart = () => {
        localStorage.removeItem(STORAGE_PRODUCTS_CART);
        getProductsCart();
    }

    //#region ValidationsForm
        (function () {
            var __indexOf = Array.prototype.indexOf || function (item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; }; return -1; };
        
            $.fn.validateCreditCard = function (callback) {
                var card_types, get_card_type, is_valid_length, is_valid_luhn, normalize, validate, validate_number;
                
                card_types = [
                    {
                        name: 'amex',
                        pattern: /^3[47]/,
                        valid_length: [15]
                    }, {
                        name: 'diners_club_carte_blanche',
                        pattern: /^30[0-5]/,
                        valid_length: [14]
                    }, {
                        name: 'diners_club_international',
                        pattern: /^36/,
                        valid_length: [14]
                    }, {
                        name: 'jcb',
                        pattern: /^35(2[89]|[3-8][0-9])/,
                        valid_length: [16]
                    }, {
                        name: 'laser',
                        pattern: /^(6304|630[69]|6771)/,
                        valid_length: [16, 17, 18, 19]
                    }, {
                        name: 'visa_electron',
                        pattern: /^(4026|417500|4508|4844|491(3|7))/,
                        valid_length: [16]
                    }, {
                        name: 'visa',
                        pattern: /^4/,
                        valid_length: [16]
                    }, {
                        name: 'mastercard',
                        pattern: /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/,
                        valid_length: [16, 20]
                    }, {
                        name: 'maestro',
                        pattern: /^(5018|5020|5038|6304|6759|676[1-3])/,
                        valid_length: [12, 13, 14, 15, 16, 17, 18, 19]
                    }, {
                        name: 'discover',
                        pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
                        valid_length: [16]
                    }
                ];

                get_card_type = function (number) {
                    var card_type, _i, _len;
                    for (_i = 0, _len = card_types.length; _i < _len; _i++) {
                        card_type = card_types[_i];
                        if (number.match(card_type.pattern)) return card_type;
                    };
                    return null;
                };

                is_valid_luhn = function (number) {
                    var digit, n, sum, _len, _ref;
                    sum = 0;
                    _ref = number.split('').reverse().join('');
                    for (n = 0, _len = _ref.length; n < _len; n++) {
                        digit = _ref[n];
                        digit = +digit;
                        if (n % 2) {
                            digit *= 2;
                            if (digit < 10) {
                                sum += digit;
                            } else {
                                sum += digit - 9;
                            }
                        } else {
                            sum += digit;
                        }
                    };
                    return sum % 10 === 0;
                };

                is_valid_length = function (number, card_type) {
                    var _ref;
                    return _ref = number.length, __indexOf.call(card_type.valid_length, _ref) >= 0;
                };

                validate_number = function (number) {
                    var card_type, length_valid, luhn_valid;
                    card_type = get_card_type(number);
                    luhn_valid = false;
                    length_valid = false;
                    if (card_type != null) {
                        luhn_valid = is_valid_luhn(number);
                        length_valid = is_valid_length(number, card_type);
                    }
                    return callback({
                        card_type: card_type,
                        luhn_valid: luhn_valid,
                        length_valid: length_valid
                    });
                };

                validate = function () {
                    var number;
                    number = normalize($(this).val());
                    return validate_number(number);
                };

                normalize = function (number) {
                    return number.replace(/[ -]/g, '');
                };

                this.bind('input', function () {
                    $(this).unbind('click');
                    return validate.call(this);
                });

                this.bind('click', function () {
                    return validate.call(this);
                });

                validate.call(this);

                return this;
            };
        }).call(this);

        function ValidateInputForm() {
            var validated = ValideCardExpiry();
            $("#card-ui-component-form-creditcard input, select").each(function () {
                var regexExpression = new RegExp($(this).attr('data-val-regex-pattern'));
                var requiredValidation = $(this).attr('data-val-required');
                if (!regexExpression.test($(this).val()) || $(this).hasClass('wrong')) {
                    $(this).addClass('card-ui-component-validation-error');
                    validated = false;
                }
                else if (((typeof requiredValidation !== typeof undefined) && (requiredValidation !== false) && ($(this).val().trim() === "")) || ($(this).val().trim() === null)) {
                    $(this).addClass('card-ui-component-validation-error');
                    validated = false;
                }
                else {
                    $(this).removeClass('card-ui-component-validation-error');
                }
            });
            return validated;
        }

        function ValidateCreditCard(element, cardType) {
            clearInterval(validateCreditCardLoadInterval);
            
            var ccValid = true;
            
            $(element).validateCreditCard(function (result) {
                if ($(element).val() === "")
                    return;
                $('ul.card-ui-component-cctypes li').removeClass('on');
                if (result.card_type != null) {
                    $('ul.card-ui-component-cctypes li.card-ui-component-' + result.card_type.name).addClass('on');
                }
                if (result.luhn_valid && result.length_valid) {
                    $(cardType).val(result.card_type.name);
                }
                if (result.luhn_valid && result.length_valid && (cardType == null ? true : (($(cardType + ' option:selected').length === 0 || result.card_type.name.toLowerCase() === $(cardType + ' option:selected').val().replace(' ', '_').toLowerCase())))) {
                    if (result.card_type.name.indexOf("master") === 0 || result.card_type.name.indexOf("visa") === 0 || result.card_type.name.indexOf("diners_club_carte_blanche") === 0 || result.card_type.name.indexOf("amex") === 0 || result.card_type.name.indexOf("discover") === 0 || result.card_type.name.indexOf("jcb") === 0) {
                        $(element).addClass('correct');
                        $(element).removeClass('wrong');
                        $(element).addClass('valid');
                        $(element).removeClass('card-ui-component-validation-error');
                    }
                    else if ($(element).attr('disabled') !== 'disabled') {
                        $(element).removeClass('correct');
                        $(element).removeClass('valid');
                        $(element).addClass('card-ui-component-validation-error');
                        $(element).addClass('wrong');
                        ccValid = false;
                    }
                }
                else if ($(element).attr('disabled') !== 'disabled') {
                    $(element).removeClass('correct');
                    $(element).addClass('wrong');
                    $(element).removeClass('valid');
                    $(element).addClass('card-ui-component-validation-error');
                    ccValid = false;
                }
            });

            return ccValid;
        }

        function ValideCardExpiry() {
            var validated = true;
            var monthelement = "#card-ui-component-ddl-expiry-month";
            var yearelement = "#card-ui-component-ddl-expiry-year";
            var month = $(monthelement).val();
            var year = $(yearelement).val();
            var current_date = new Date();
            if (month === "0" || month === "-1") {
                validated = false;
            }
            else if (month !== "0" && year !== "0" && (month < current_date.getMonth() + 1 && current_date.getFullYear().toString() === year)) {
                $(yearelement).addClass("wrong card-ui-component-validation-error");
                $(monthelement).addClass("wrong card-ui-component-validation-error");
                validated = false;
            }
            else {
                $(yearelement).removeClass("wrong card-ui-component-validation-error");
                $(monthelement).removeClass("wrong card-ui-component-validation-error");
            }
            return validated;
        }

        $(document).ready(function () {
            validateCreditCardLoadInterval = setInterval(function () {
                if ($.fn.validateCreditCard) {
                    ValidateCreditCard($("#card-ui-component-txt-creditcard-number"), null);
                }
            }, 1000);

            $("#card-ui-component-form-creditcard input").change(function () {
                var regexExpression = new RegExp($(this).attr('data-val-regex-pattern'));
                var requiredValidation = $(this).attr('data-val-required');
                
                if (!regexExpression.test($(this).val()) || $(this).hasClass('wrong')) {
                    $(this).addClass('card-ui-component-validation-error');
                }
                else if (((typeof requiredValidation !== typeof undefined) && (requiredValidation !== false) && ($(this).val().trim() === "")) || ($(this).val().trim() == null)) {
                    $(this).addClass('card-ui-component-validation-error');
                }
                else {
                    $(this).removeClass('card-ui-component-validation-error');
                }
            });
        });
    //#endregion ValidationsForm

    //#region Payment
        async function encryptCardInformation(cardNumber, cardHolder, month, year) {
            var responseCode = '';
            var accountToken = '';
            var securedNumber = '';

            try {
                const key = initializeKey("010001", "FBC0991AFD7D4FF2C9ADDE21022EFD9B289D999BC48E4C9B9A1AD2CCBD58FFD1A090C3A2D613FE0F769B7F16DF9E841A6273BC933C2DA43925209AAFC13EEED20F77A74316798DF8AB8E8231F6892955935A3DCADF54A3FD2048F07D70F61D922EC11A15D559F9392C56A113AC1B1BB3CAFA6DA8F1A6E46BB3204C39F2C98D29");
            
                var encrypted = encryptedString(key,
                    base64encode(cardNumber) + "\\" +
                    base64encode(cardHolder) + "\\" +
                    base64encode(month) + "\\" +
                    base64encode(year) + "\\" +
                    base64encode("") + "\\" +
                    base64encode("") + "\\" +
                    base64encode(KEY_API_BAC) + "\\" +
                    base64encode("es")
                );

                await $.ajax({
                    "type": "POST",
                    "url": URL_WIDGET,
                    "data": { "request": encrypted },
                    "success": function (jsonEncryptedResponse) {
                        responseCode = jsonEncryptedResponse.response.ResponseCode;
                        accountToken = jsonEncryptedResponse.response.TokenDetails.AccountToken;
                        securedNumber = jsonEncryptedResponse.securedNumber;

                        console.log('STC');
                    },
                    "error": function (jsonEncryptedResponse) {
                        //console.log('Error en encryptCardInformation: ' + jsonEncryptedResponse);
                    }
                });
            }
            catch(e)
            {
               //console.log('Error al intentar validar el método de pago: ' + e); 
            }

            return { responseCode, accountToken, securedNumber };
        }

        async function makePayment(accountToken, cvv) {
            var responseTransactionId = '';
            var responseCode = '';

            if((id == null || id == '') || (name == null || name == '') || (email == null || email == ''))
            {
                getAccountInformation();
            }

            try
            {
                //#region SoapMessage
                    var soapMessage =   '<?xml version="1.0" encoding="utf-8"?>' +
                                        '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">' +
                                            '<soap:Body>' +
                                                '<Rebill xmlns="http://tempuri.org/">' +
                                                '<APIKey>' + KEY_API_BAC + '</APIKey>' +
                                                '<accountToken>' + accountToken + '</accountToken>' +
                                                '<accessCode>123123</accessCode>' +
                                                '<merchantAccountNumber>' + Merchant + '</merchantAccountNumber>' +
                                                '<terminalName>' + Terminal + '</terminalName>' +
                                                '<clientTracking>NA</clientTracking>' +
                                                '<amount>' + cartTotalPrice.toFixed(2) + '</amount>' +
                                                '<currencyCode>840</currencyCode>' +
                                                '<emailAddress>' + email + '</emailAddress>' +
                                                '<shippingName>' + name + '</shippingName>' +
                                                '<shippingDate>' + Date.UTC() + '</shippingDate>' +
                                                '<shippingAddress>Costa Rica</shippingAddress>' +
                                                '<shippingCity>San Jose</shippingCity>' +
                                                '<shippingState>Procesado</shippingState>' +
                                                '<shippingCountry>Costa Rica</shippingCountry>' +
                                                '<shippingZipCode>10101</shippingZipCode>' +
                                                '<shippingPhoneNumber>' + phone + '</shippingPhoneNumber>' +
                                                '<billingAddress>Costa Rica</billingAddress>' +
                                                '<billingCity>San Jose</billingCity>' +
                                                '<billingState>Procesado</billingState>' +
                                                '<billingCountry>Costa Rica</billingCountry>' +
                                                '<billingZipCode>10101</billingZipCode>' +
                                                '<billingPhoneNumber>' + phone + '</billingPhoneNumber>' +
                                                '<itemDetails>' +
                                                    '<ItemDetails>' +
                                                        '<ExtensionData/>' +
                                                        '<Code>string</Code>' +
                                                        '<Name>Plan de bienestar Aura</Name>' +
                                                        '<Description>Plan de bienestar Aura</Description>' +
                                                        '<Quantity>1</Quantity>' +
                                                        '<UnitPrice>' + cartTotalPrice.toFixed(2) + '</UnitPrice>' +
                                                    '</ItemDetails>' +
                                                '</itemDetails>' +
                                                '<systemTracking>NA</systemTracking>' +
                                                '<additionalData>' +
                                                    '<AdditionDataDetail>' +
                                                        '<ExtensionData/>' +
                                                        '<Name>Pago Mensual</Name>' +
                                                        '<Value>' + cartTotalPrice.toFixed(2) + '</Value>' +
                                                    '</AdditionDataDetail>' +
                                                '</additionalData>' +
                                                '<cvv>' + cvv + '</cvv>' +
                                                '</Rebill>' +
                                            '</soap:Body>' +
                                        '</soap:Envelope>';
                //#endregion SopaMessage

                await $.ajax({
                    "type": "POST",
                    "url": URL_API,
                    "dataType": "xml",
                    "contentType": "text/xml",
                    "data": soapMessage,
                    // "headers": {
                    //     "Access-Control-Allow-Origin": "http://localhost:3000",
                    //     "Access-Control-Allow-Headers": "accept, content-type",
                    //     "Access-Control-Allow-Methods": "POST, GET, OPTIONS"
                    // },
                    "success": function (dataEncryptedResponse) {
                        responseTransactionId = $(dataEncryptedResponse).find("RebillResult").find("TransactionId").text();
                        responseCode = $(dataEncryptedResponse).find("RebillResult").find("Code").text();

                        console.log('SPC');
                    },
                    "error": function (dataEncryptedResponse) {
                        // console.log('ERROR AQUI: makePayment');
                        // console.log('dataEncryptedResponse: ' + dataEncryptedResponse);
                    }
                });
            }
            catch(e)
            {
                //console.log('Error al intentar realizar el pago: ' + e); 
            }

            return { responseTransactionId, responseCode };
        }

        async function makePaymentAlternative(accountToken, cvv) {
            var responseTransactionId = '';
            var responseCode = '';

            if((id == null || id == '') || (name == null || name == '') || (email == null || email == ''))
            {
                getAccountInformation();
            }

            try
            {
                //#region SoapMessage
                    var soapMessage =   '<?xml version="1.0" encoding="utf-8"?>' +
                                        '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">' +
                                            '<soap:Body>' +
                                                // '<Rebill xmlns="http://tempuri.org/">' +
                                                '<Sale xmlns="http://tempuri.org/">' +
                                                '<APIKey>' + KEY_API_BAC + '</APIKey>' +
                                                '<accountToken>' + accountToken + '</accountToken>' +
                                                '<accessCode>123123</accessCode>' +
                                                '<merchantAccountNumber>' + Merchant + '</merchantAccountNumber>' +
                                                '<terminalName>' + Terminal + '</terminalName>' +
                                                '<clientTracking>NA</clientTracking>' +
                                                '<amount>' + cartTotalPrice.toFixed(2) + '</amount>' +
                                                '<currencyCode>840</currencyCode>' +
                                                '<emailAddress>' + email + '</emailAddress>' +
                                                '<shippingName>' + name + '</shippingName>' +
                                                '<shippingDate>' + Date.UTC() + '</shippingDate>' +
                                                '<shippingAddress>Costa Rica</shippingAddress>' +
                                                '<shippingCity>San Jose</shippingCity>' +
                                                '<shippingState>Procesado</shippingState>' +
                                                '<shippingCountry>CR</shippingCountry>' +
                                                '<shippingZipCode>10101</shippingZipCode>' +
                                                '<shippingPhoneNumber>' + phone + '</shippingPhoneNumber>' +
                                                '<billingAddress>Costa Rica</billingAddress>' +
                                                '<billingCity>San Jose</billingCity>' +
                                                '<billingState>Procesado</billingState>' +
                                                '<billingCountry>CR</billingCountry>' +
                                                '<billingZipCode>10101</billingZipCode>' +
                                                '<billingPhoneNumber>' + phone + '</billingPhoneNumber>' +
                                                '<itemDetails>' +
                                                    '<ItemDetails>' +
                                                        '<ExtensionData/>' +
                                                        '<Code>string</Code>' +
                                                        '<Name>Plan de bienestar Aura</Name>' +
                                                        '<Description>Plan de bienestar Aura</Description>' +
                                                        '<Quantity>1</Quantity>' +
                                                        '<UnitPrice>' + cartTotalPrice.toFixed(2) + '</UnitPrice>' +
                                                    '</ItemDetails>' +
                                                '</itemDetails>' +
                                                '<systemTracking>NA</systemTracking>' +
                                                '<additionalData>' +
                                                    '<AdditionDataDetail>' +
                                                        '<ExtensionData/>' +
                                                        '<Name>Pago Mensual</Name>' +
                                                        '<Value>' + cartTotalPrice.toFixed(2) + '</Value>' +
                                                    '</AdditionDataDetail>' +
                                                '</additionalData>' +
                                                '<cvv>' + cvv + '</cvv>' +
                                                // '</Rebill>' +
                                                '</Sale>' +
                                            '</soap:Body>' +
                                        '</soap:Envelope>';
                //#endregion SopaMessage

                const res = await fetch(URL_API_AURA + '/ExecutePayment?xmlPayment=' + soapMessage + '&clientSecret=' + FINAL_KEY_API_AURA,
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                    }
                );

                var response = await res.json();

                var responseTransactionId = response.body.saleResponse.saleResult.transactionId;
                var responseCode = response.body.saleResponse.saleResult.code;

                console.log('SPIPC');

                return { responseTransactionId, responseCode };
            }
            catch (e)
            {
                
            }
        }

        async function postProcessSale(transactionId, securedNumber, cvv, accountToken) {
            var arrayProducts = [];

            singleProductsCart.forEach((idProductsCart, index) => {
                var arrayProduct = {
                    id: idProductsCart,
                    quantity: countDuplicatesItemArray(idProductsCart, productsCart)
                };

                arrayProducts.push(arrayProduct);
            });

            var receptor;

            requiredBill == true
            ?   receptor = {
                    nombre: name,
                    tipoIdent: typeId,
                    identificacion: id,
                    nombComercial: name,
                    areaTelefono: 506,
                    numTelefono: phone,
                    correo: email,
                    copia: '',
                    destinatario: '',
                    direccion: '',
                    provincia: 0,
                    canton: 0,
                    distrito: 0,
                    barrio: 0,
                }
            :   receptor = {
                    nombre: 'NA',
                    tipoIdent: 0,
                    identificacion: 'NA',
                    nombComercial: 'NA',
                    areaTelefono: 506,
                    numTelefono: 0,
                    correo: 'NA',
                    copia: 'NA',
                    destinatario: 'NA',
                    direccion: 'NA',
                    provincia: 0,
                    canton: 0,
                    distrito: 0,
                    barrio: 0,
                };
    
            const user = JSON.parse(localStorage.getItem('uli_acr'));

            try {
                const res = await fetch(URL_API_AURA + '/ProcessSale?username=' + user.uli + '&clientSecret=' + FINAL_KEY_API_AURA,
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(
                            {
                                ArrayProducts: arrayProducts,                             
                                RequiredBill: requiredBill,
                                TransactionId: transactionId,
                                SecuredNumber: securedNumber,
                                CardCode: '0',
                                Account: accountToken,
                                CartSubTotalPrice: cartSubTotalPrice.toFixed(2),
                                CartIvaPrice: cartIvaPrice.toFixed(2),
                                CartTotalPrice: cartTotalPrice.toFixed(2),
                                Receptor: receptor
                            }
                        )
                    }
                );

                //console.log(res);
                
                // const json = await res.json();             
            }
            catch (e)
            {
                
            }
        }
    //#endregion Payment

    const onSubmit = async (e) => {
        e.preventDefault();

        if (ValidateInputForm()) { 
            setLoading(true);

            var cardNumber = $("#card-ui-component-txt-creditcard-number").val();
            var cardHolder = $("#card-ui-component-txt-creditcard-holder").val();
            var month = $("#card-ui-component-ddl-expiry-month").val();
            var year = $("#card-ui-component-ddl-expiry-year").val();
            var cvv = $("#card-ui-component-txt-creditcard-security").val();

            var encryptedInformation = await encryptCardInformation(cardNumber, cardHolder, month, year);

            if(encryptedInformation.responseCode == 'T00')
            {
                // var responseMakePayment = await makePayment(encryptedInformation.accountToken, cvv);
                var responseMakePayment = await makePaymentAlternative(encryptedInformation.accountToken, cvv);

                if(responseMakePayment.responseCode == '00' || responseMakePayment.responseCode == '11')
                {
                    console.log('SPC');

                    var response = await postProcessSale(responseMakePayment.responseTransactionId, encryptedInformation.securedNumber, cvv, encryptedInformation.accountToken);

                    setMessagePayment(responseMakePayment.responseTransactionId);
                    emptyCart();
                    setSuccessfulPayment(true);
                }
                else if('' == 'B01' || '' == 'B02' || '' == '06')
                {
                    setMessagePayment('Llamar al banco emisor'); 
                }
                else if('' == 'B12' || '' == '39')
                {
                    setMessagePayment('Transacción inválida'); 
                }
                else if('' == 'B13')
                {
                    setMessagePayment('Monto inválido'); 
                }
                else if('' == 'B14')
                {
                    setMessagePayment('Tarjeta inválida'); 
                }
                else if('' == 'B19')
                {
                    setMessagePayment('Reintente más tarde, tiempo de espera agotado'); 
                }
                else if('' == '31')
                {
                    setMessagePayment('Banco emisor no permitido'); 
                }
                else if('' == 'B51')
                {
                    setMessagePayment('Fondos insuficientes'); 
                }
                else if('' == '52' || '' == '53')
                {
                    setMessagePayment('Cuenta inválida'); 
                }
                else
                {
                    setMessagePayment('Ha ocurrido un error, no se pudo realizar el pago - Cod: ' + responseMakePayment.responseCode); 
                }
            }
            else
            {
                setMessagePayment('Se ha producido un error al intentar validar el método de pago, no se realizó ningún cobro'); 
            }
            
            setLoading(false);
        }
        
        return false;
    }

    if(successfulPayment)
    {
        return <Successful TransactionId={messagePayment}/>
    }

    return (
        <>    
            <div className='form-payment'>
                <Form onSubmit={onSubmit} id="card-ui-component-form-creditcard">
                    <Row>
                        <ElectronicBill
                            termsConditionsChangeValue={termsConditionsChangeValue}
                            billLoading={billLoading}
                            requiredBillChangeValue={requiredBillChangeValue}
                            setTypeId={setTypeId}
                            setId={setId}
                            setName={setName}
                            setEmail={setEmail}
                            setPhone={setPhone}
                            requiredBill={requiredBill}
                            typeId={typeId}
                            id={id}
                            name={name}
                            email={email}
                            phone={phone}
                        />
                        <DataCard
                            termsConditions={termsConditions}
                            ValideCardExpiry={ValideCardExpiry}
                            cartTotalPrice={cartTotalPrice}
                            messagePayment={messagePayment}
                            loading={loading}
                        />
                    </Row>
                </Form>
            </div>
        </>
    );
}

function ElectronicBill(props) {

    const { termsConditionsChangeValue, billLoading, requiredBillChangeValue,
        setTypeId, setId, setName, setEmail, setPhone, 
        requiredBill, typeId, id, name, email, phone } = props;

    return (
        <Col xs={12} lg={6}>
            <div className="main">
                <div className='main-card'>
                    <h3>Términos y Condiciones</h3>

                    <div className="main-card__form">
                        <Row>
                            <Col xs={2} lg={2}>
                                <input type="checkbox" onClick={termsConditionsChangeValue} />
                            </Col>
                            <Col xs={10} lg={10}>
                                <label>He leído y estoy de acuerdo con las condiciones generales de cada plan seleccionado</label>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="main">
                <div className='main-card'>
                    <h3>Factura Electrónica</h3>

                    <div className="main-card__form">
                        <Row>
                            <Col xs={2} lg={2}>
                                <input type="checkbox" onClick={requiredBillChangeValue} />
                            </Col>
                            <Col xs={10} lg={10}>
                                <label>Requiero factura electrónica</label>
                            </Col>
                        </Row>
                        
                        {
                            requiredBill == true
                                ?   billLoading == true
                                    ? <Loading/>
                                    :   <>
                                            <p className='messagePayment'>* Puedes cambiar los datos de los espacios en caso de que lo requieras</p>
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <label>Tipo identificación</label>
                                                    <select onChange={(e) => setTypeId(e.target.value)} value={typeId} >
                                                        <option value='1'>Cédula física</option>
                                                        <option value='2'>Cédula jurídica</option>
                                                        <option value='3'>Dimex</option>
                                                        <option value='4'>Nite</option>
                                                        <option value='10'>Extranjera</option>
                                                    </select>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <label>Identificación</label>
                                                    <input type="text" placeholder="Identificación" required onChange={(e) => setId(e.target.value)} value={id} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <label>Nombre</label>
                                                    <input type="text" placeholder="Nombre" required onChange={(e) => setName(e.target.value)} value={name} />
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <label>Correo Electrónico</label>
                                                    <input type="email" placeholder="Correo electrónico" required onChange={(e) => setEmail(e.target.value)} value={email} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <label>Teléfono</label>
                                                    <input type="text" placeholder="Teléfono" required onChange={(e) => setPhone(e.target.value)} value={phone} />
                                                </Col>
                                            </Row>
                                        </>
                                : null
                        }               
                        
                    </div>                                                
                </div>             
            </div>
        </Col>
    );
}

function DataCard(props) {

    const { termsConditions, ValideCardExpiry, cartTotalPrice, messagePayment, loading } = props;

    return (
        <>
            <Col xs={1} lg={1}></Col>    
            <Col xs={10} lg={4}>
                <div className="card-ui-component-outer">
                    <div className="card-ui-component-heading">Datos de la Tarjeta</div>

                    {/* <div id="card-ui-component-notification-container" class="card-ui-component-alert card-ui-component-alert-danger"></div> */}
                    
                    <div className="card-ui-component-inner-body">
                        <div className="card-ui-component-row-static">
                            <div className="card-ui-component-label-style">
                                <label htmlFor="Titular_de_la_Tarjeta">Titular de la Tarjeta</label>
                            </div>

                            <div className="card-ui-component-input-style">
                                <input className="card-ui-component-field" data-val="true" 
                                    data-val-regex="Titular de la Tarjeta no es válida" data-val-regex-pattern="^([^;&quot;]*)$" 
                                    data-val-required="Titular de la Tarjeta no es válida" 
                                    id="card-ui-component-txt-creditcard-holder" maxLength="30" name="CardHolderName" type="text"
                                />
                            </div>
                        </div>

                        <div className="card-ui-component-row-static">
                            <div className="card-ui-component-label-style">
                                <label className="" htmlFor="N_mero_de_Tarjeta">Número de Tarjeta</label>
                            </div>
                            <div className="card-ui-component-input-style">
                                <input className="card-ui-component-field" data-val="true" 
                                    data-val-regex="Número de la Tarjeta no es válida" data-val-regex-pattern="^[0-9xX*]{13,16}$" 
                                    data-val-required="Número de la Tarjeta no es válida" 
                                    id="card-ui-component-txt-creditcard-number" maxLength="16" name="Number" type="text"
                                />
                                
                                <div className="card-ui-component-card-bg">
                                    <ul className="card-ui-component-cctypes">
                                        <li className="card-ui-component-mastercard"></li>
                                        <li className="card-ui-component-visa"></li>
                                        <li className="card-ui-component-diners_club_carte_blanche"></li>
                                        <li className="card-ui-component-amex"></li>
                                        <li className="card-ui-component-discover"></li>
                                        <li className="card-ui-component-jcb"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card-ui-component-row-static">
                            <div className="card-ui-component-label-style">
                                <label htmlFor="Fecha_de_Caducidad">Fecha de Caducidad</label>
                            </div>
                            
                            <div className="card-ui-component-input-style">
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <select className="card-ui-component-ddl-expiry feild select_input" data-val="true" 
                                            data-val-regex="Mes de Caducidad no es válido" data-val-regex-pattern="^[0-9]*$" 
                                            id="card-ui-component-ddl-expiry-month" name="ExpirationMonth" onChange={ValideCardExpiry}>
                                                <option>01</option>
                                                <option>02</option>
                                                <option>03</option>
                                                <option>04</option>
                                                <option>05</option>
                                                <option>06</option>
                                                <option>07</option>
                                                <option>08</option>
                                                <option>09</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                        </select>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <select className="card-ui-component-ddl-expiry" data-val="true" 
                                            data-val-regex="Año de Caducidad no es válido" data-val-regex-pattern="^[0-9]*$" 
                                            id="card-ui-component-ddl-expiry-year" name="ExpirationYear" onChange={ValideCardExpiry}>
                                                <option>2023</option>
                                                <option>2024</option>
                                                <option>2025</option>
                                                <option>2026</option>
                                                <option>2027</option>
                                                <option>2028</option>
                                                <option>2029</option>
                                                <option>2030</option>
                                                <option>2031</option>
                                                <option>2032</option>
                                                <option>2033</option>
                                                <option>2034</option>
                                                <option>2035</option>
                                                <option>2036</option>
                                                <option>2037</option>
                                                <option>2038</option>
                                                <option>2039</option>
                                                <option>2040</option>
                                                <option>2041</option>
                                                <option>2042</option>
                                        </select>
                                    </Col>
                                </Row> 
                            </div>
                        </div>

                        <div className="card-ui-component-row-static">
                            <div className="card-ui-component-label-style">
                                <label className="" htmlFor="Codigo_de_Tarjeta">Código de Seguridad</label>
                            </div>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <div className="card-ui-component-input-style">
                                        <input className="card-ui-component-field" data-val="true" 
                                            data-val-regex="Código no es válido" data-val-regex-pattern="^[0-9xX*]{3,4}$"
                                            data-val-required="Código de la Tarjeta no es válida" 
                                            id="card-ui-component-txt-creditcard-security" maxLength="4" name="Number" type="password"
                                        />
                                    </div>
                                </Col>
                            </Row>                          
                        </div>

                        <div className="border-line"></div>
                        
                        <div className='card-ui-component-totalPrice'>
                            <p><b>Total a pagar: ${cartTotalPrice.toFixed(2)}</b></p>
                        </div>

                        <div className="border-line"></div>

                        {
                            termsConditions == false
                            ?   null
                            :   cartTotalPrice <= 0
                                ? null
                                : loading == true
                                    ?   <Processing/>
                                    :   <>
                                            <p className='messagePayment'>{messagePayment}</p>
                                            <div className="card-ui-component-action-container">
                                                {/* <Button name="save" type="submit" className="card-ui-component-save-btn card-ui-component-btn-action" id="card-ui-component-btn-confirm">Pagar</Button> */}
                                            </div>
                                        </>
                            
                        }
                    </div>
                </div>
            </Col>
            <Col xs={1} lg={1}></Col>
        </>
    );
}
