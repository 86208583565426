import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Product from '../product';
import Loading from '../loading';
import ImageBackground from '../../assets/img/content/Fondo1.jpg';

import './products.scss';

export default function Products(props) {

    const {
        products: { result, loading, error },
        addProductCart
    } = props;

    return (
        <>
            <Container>
                <div className='title-plans'>
                    <h1>Adquiere tu plan Aura</h1>
                </div>

                <Row>
                    { 
                        loading || !result 
                            ? (
                                <Loading/>
                            )
                            : result.map((product, index) => (
                                <Product
                                    key={index}
                                    product={product}
                                    addProductCart={addProductCart} 
                                />
                            ))
                    }
                </Row>
            </Container>
        </>     
    );
}
