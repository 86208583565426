import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Background from '../../assets/img/content/Contacto.jpg';
import WhatsappImg from '../../assets/img/logos/Whatsapp.png';
import Facebook from '../../assets/img/logos/Facebook.png';
import Instagram from '../../assets/img/logos/Instagram.png';
import Waze from '../../assets/img/logos/Waze.png';
import Maps from '../../assets/img/logos/Maps.png';

import Footer from '../../components/footer';

import './contacto.scss';

export default function Contacto() {
    return (
        <>
            <div className='contacto-background'>
                <img src={Background} alt='contacto-background' />
            </div>
            <Container>
                <div className='contacto'>
                    <h1>Contacto</h1>
                    <Row>
                        <Col xs={12} lg={12} >
                            <h3>¿Tienes preguntas?</h3>
                            <p>Escríbenos a nuestro Whatsapp o redes sociales para despejar cualquier duda.</p>
                            <div className='contacto-redesImg'>
                                <a rel="noopener noreferrer" target='_blank' href='https://api.whatsapp.com/send/?phone=50664791168&text&type=phone_number&app_absent=0'><img src={WhatsappImg} alt='whatsapp'/></a>
                                <a rel="noopener noreferrer" target='_blank' href='https://www.facebook.com/aurabienestarcr'><img src={Facebook} alt='facebook'/></a>
                                <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/auraesbienestar'><img src={Instagram} alt='instagram'/></a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12} >
                            <h3>Dirección</h3>
                            <p>Av 2, calle 30, 150 norte del Hotel Grano de Oro, San José, Costa Rica.</p>
                            <div className='contacto-redesImg'>
                                <a rel="noopener noreferrer" target='_blank' href='https://ul.waze.com/ul?ll=9.93469443%2C-84.09296036&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'><img src={Waze} alt='waze'/></a>
                                <a rel="noopener noreferrer" target='_blank' href='https://goo.gl/maps/tXgTcVA6eEKKn4jm7'><img src={Maps} alt='maps'/></a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12} >
                            <h3>Correo Electrónico</h3>
                            <p>info@aura-cr.com</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12} >
                            <h3>Teléfono para Asistencia</h3>
                            <p>+(506) 2528-7547</p>
                        </Col>
                    </Row>
                </div>
            </Container>            
            <Footer/>      
        </>
    );
}