
export default function useFecth(url, options) {
    
    (async () => {     
        try {
            const res = await fetch(url, options);
            const json = await res.json();

            return { res, json };
        }
        catch (err)
        {
            return { err };
        }
    })();
};

// import { useState } from 'react';

// export default function useFecth(url, options) {
    
//     const [loading, setLoading] = useState(true);
//     const [result, setResult] = useState(null);
//     const [error, setError] = useState(null); 

//     (async () => {
            
//         try {
//             const res = await fetch(url, options);
//             const json = await res.json();
//             setResult(json);
//             setLoading(false);
//         }
//         catch (err)
//         {
//             setError(err);
//             setLoading(false);
//         }

//     })();

//     return { loading, result, error }
// };