import React from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './product.scss';

export default function Product(props) {
    
    const { product, addProductCart } = props;

    return (
        <Col xs={12} lg={4} className='product'>
            <Card>
                <Card.Img variant='top' src={product.plan.imagen} />
                <Card.Body>
                    <Card.Text>{product.plan.nombre}</Card.Text>
                    <Card.Title><b>${product.plan.costo} + iva</b></Card.Title>
                    <Card.Text>Mensual</Card.Text>                   
                    <ResumeProduct listBeneficioPlan={product.listBeneficioPlan} />

                    <Link to={'/Plan/' + product.plan.id}>
                        <Button>
                            Ver más...
                        </Button>
                    </Link>
                    
                    <Button onClick={() => addProductCart(product.plan.id, product.plan.nombre)}>
                        Añadir al carrito
                    </Button>
                </Card.Body>
            </Card>
        </Col>
    );
}

function ResumeProduct(props) {
    const { listBeneficioPlan } = props;

    return (
        <div className='product-resume'>
            { 
                !listBeneficioPlan
                    ? null
                    : listBeneficioPlan.map((item, index) => (
                        <LineResumeProduct key={index} beneficio={item.proveedorBeneficio.beneficio.nombre} />
                    ))
            }
        </div>
    );
}

function LineResumeProduct(props) {
    const { beneficio } = props;

    return (
        <Card.Text><b>✓</b> {beneficio.substr(0, 40)}... </Card.Text>
    );
}
