
export const countDuplicatesItemArray = (value, array) => {
    let count = 0;

    array.forEach(arrayValue => {
        if(arrayValue == value) {
            count++;
        }
    });

    return count;
};

export const removeArrayDuplicates = array => {
    return Array.from(new Set(array));
};

export const removeItemArray = (array, item) => {
    const index = array.indexOf(item);

    if(index > -1) {
        array.splice(index, 1);
    }

    return array;
};

export const b64EncodeUnicode = (value) => {
    // return btoa(encodeURIComponent(value).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    //     return String.fromCharCode('0x' + p1);
    // }));

    const base64 = require('base-64');
    return base64.encode(value);
}

export const fromB64EncodeUnicode = (value) => {
    // return atob(value);
    // return Buffer.from(value, 'base64').toString('ascii');

    const base64 = require('base-64');
    return base64.decode(value);
}