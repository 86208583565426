import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../../../utils/constants';

import Loading from '../../../../components/loading';
import Footer from '../../../../components/footer';

import '../../../../auth/pages/account.scss';

export default function Edit() {

    const user = JSON.parse(localStorage.getItem('uli_acr'));

    useEffect(() => {
        getAccountInformation();
    }, []);

    //#region form
        const [idAccount, setIdAccount] = useState('');
        const [email, setEmail] = useState('');
        const [id, setId] = useState('');
        const [firstName, setFirstName] = useState('');
        const [secondName, setSecondName] = useState('');
        const [firstLastName, setFirstLastName] = useState('');
        const [secondLastName, setSecondLastName] = useState('');
        const [birthday, setBirthday] = useState('');
        const [phone, setPhone] = useState('');
        const [gender, setGender] = useState(3);
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
    //#endregion form

    //#region validations
        const [letter, setLetter] = useState(false);
        const [upper, setUpper] = useState(false);
        const [number, setNumber] = useState(false);
        const [length, setLength] = useState(false);
        const [space, setSpace] = useState(true);
        const [equal, setEqual] = useState(true);
    //#endregion validations

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); 

    const navigate = useNavigate();

    async function getAccountInformation() {
        setLoading(true);

        const user = JSON.parse(localStorage.getItem('uli_acr'));

        try {
            const res = await fetch(URL_API_AURA + '/AccountInformation?username=' + user.uli + '&clientSecret=' + FINAL_KEY_API_AURA, null);
            const json = await res.json();
            // setBillResult(json);
            
            setIdAccount(json.id);
            setEmail(json.email);
            setId(json.identificacion);
            setFirstName(json.primerNombre);
            setSecondName(json.segundoNombre);
            setFirstLastName(json.primerApellido);
            setSecondLastName(json.segundoApellido);
            setBirthday(moment(json.fechaNacimiento).format('YYYY-MM-DD'));
            setGender(json.genero.id);

            setLoading(false);
        }
        catch (e)
        {
            setError('Se ha producido un error al intentar cargar la información del perfil');
            setLoading(false);
        }
        finally
        {
            setLoading(false); 
        }
    }

    const toPerfil = () => {
        navigate('/Perfil', {
            replace: true
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await fetch(URL_API_AURA + '/UpdateAccountInformation?clientSecret=' + FINAL_KEY_API_AURA,
                {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(
                            {
                                UsuarioId: idAccount,
                                Identificacion: id,                             
                                PrimerNombre: firstName,
                                SegundoNombre: secondName,
                                PrimerApellido: firstLastName,
                                SegundoApellido: secondLastName,
                                FechaNacimiento: birthday,
                                TelefonoDomicilio: phone,
                                TelefonoOficina: '',
                                Email: email,
                                GeneroId: gender
                            }
                        )
                    }
            );
            
            if(res.status != 200)
            {
                setError('Se ha producido un error al actualizar la información del perfil');
            }
            else
            {
                toPerfil();
            }          
        }
        catch (e)
        {
            setError('Se ha producido un error al intentar actualizar la información del perfil');
        }

        setLoading(false);
    }

    return (
        <>
            <Row>
                <Col xs={1} lg={2}></Col>
                
                <Col xs={12} lg={8}>
                    <div className="main">
                        <div className='main-card'>
                            <h3>Editar Mi Perfil</h3>

                            {
                                loading == true
                                ?   <Loading/>
                                :   <Form onSubmit={onSubmit} className="main-card__form">
                                        <Row>
                                            <Col  xs={12} lg={6}>
                                                <label>Correo Electrónico</label>
                                                <input type="email" placeholder="Correo electrónico" required onChange={(e) => setEmail(e.target.value)} value={email}/>
                                            </Col>
                                            <Col  xs={12} lg={6}>
                                                <label>Identificación</label>
                                                <input type="text" placeholder="Identificación" readOnly value={id}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col  xs={12} lg={6}>
                                                <label>Primer Nombre</label>
                                                <input type="text" placeholder="Primer Nombre" required onChange={(e) => setFirstName(e.target.value)} value={firstName}/>
                                            </Col>
                                            <Col  xs={12} lg={6}>
                                                <label>Segundo Nombre</label>
                                                <input type="text" placeholder="Segundo Nombre" onChange={(e) => setSecondName(e.target.value)} value={secondName}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col  xs={12} lg={6}>
                                                <label>Primer Apellido</label>
                                                <input type="text" placeholder="Primer Apellido" required onChange={(e) => setFirstLastName(e.target.value)} value={firstLastName}/>
                                            </Col>
                                            <Col  xs={12} lg={6}>
                                                <label>Segundo Apellido</label>
                                                <input type="text" placeholder="Segundo Apellido" required onChange={(e) => setSecondLastName(e.target.value)} value={secondLastName}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col  xs={12} lg={6}>
                                                <label>Fecha Nacimiento</label>
                                                <input type="date" placeholder="Fecha Nacimiento" required readOnly value={birthday}/>
                                            </Col>
                                            <Col  xs={12} lg={6}>
                                                <label>Género</label>
                                                {/* <input type="text" placeholder="Género" required onChange={(e) => setGender(e.target.value)} value={gender}/> */}
                                                <select onChange={(e) => setGender(e.target.value)} value={gender}>
                                                    <option value='1'>Masculino</option>
                                                    <option value='2'>Femenino</option>
                                                    <option value='3'>Otro</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col  xs={12} lg={6}>
                                                <label>Teléfono</label>
                                                <input type="text" placeholder="Teléfono" required onChange={(e) => setPhone(e.target.value)} value={phone}/>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col  xs={12} lg={6}>
                                                <label>Contraseña</label>
                                                <input id='password' type="password" placeholder="Contraseña" required minLength={8} onChange={(e) => setPassword(e.target.value)} value={password}/>
                                            </Col>
                                            <Col  xs={12} lg={6}>
                                                <label>Confirmar Contraseña</label>
                                                <input id='confirmPassword' type="password" placeholder="Confirmar Contraseña" required minLength={8} onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword}/>
                                            </Col>
                                        </Row> */}

                                        {/* <div className='main-card__form-validation'>
                                            <p className={letter === true ? 'main-card__form-validation__correct' : 'main-card__form-validation__wrong'} >• Al menos una letra</p>
                                            <p className={upper === true ? 'main-card__form-validation__correct' : 'main-card__form-validation__wrong'} >• Al menos una letra mayúscula</p>
                                            <p className={number === true ? 'main-card__form-validation__correct' : 'main-card__form-validation__wrong'} >• Al menos un número</p>
                                            <p className={length === true ? 'main-card__form-validation__correct' : 'main-card__form-validation__wrong'} >• Al menos 8 carácteres</p>
                                            <p className={space === true ? 'main-card__form-validation__correct' : 'main-card__form-validation__wrong'} >• No debe contener espacios</p>
                                            <p className={equal === true ? 'main-card__form-validation__correct' : 'main-card__form-validation__wrong'} >• Las contraseñas deben coincidir</p>
                                        </div> */}
                                        
                                        {/* {
                                            ((letter === true) && (upper === true) && (number === true) && (length === true) && (space === true) &&(equal === true))
                                                ?   <Button type="submit">
                                                        Guardar
                                                    </Button>
                                                : ''
                                        }     */}

                                        <Button type="submit">
                                            Guardar
                                        </Button>
                                    </Form> 
                            }

                            <p className="main-card__error">{error}</p>
                                                                           
                        </div>             
                    </div>
                </Col>
                
                <Col xs={1} lg={2}></Col>
            </Row>

            <Footer/>         
        </>
    );
}