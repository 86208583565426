import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Tab, Tabs, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import DropDown from '@mui/material/Menu';
import DropDownItem from '@mui/material/MenuItem';
import { fromB64EncodeUnicode } from '../../../utils/arrayFunc';

import Image from '../../../assets/img/logos/LogoAzul.png';
import Facebook from '../../../assets/img/logos/Facebook.png';
import Instagram from '../../../assets/img/logos/Instagram.png';

import Cart from '../../cart';
import DrawerComp from '../drawer';

import './menu.scss';

export default function Menu(props) {

    const { productsCart, getProductsCart, products, user, logout } = props;

    const [menuOption, setMenuOption] = useState(0);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("lg"));

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLogout = () => {
        setMenuOption(0);
        handleClose();

        logout();
        navigate('/Login', {
            replace: true
        });
    }

    const onInicio = () => {
        setMenuOption(0);
        handleClose();

        navigate('/', {
            replace: true
        });
    }

    const onPlanes = () => {
        setMenuOption(1);
        handleClose();

        navigate('/Planes', {
            replace: true
        });
    }

    const onEmpresa = () => {
        setMenuOption(2);
        handleClose();

        navigate('/Empresa', {
            replace: true
        });
    }

    const onContacto = () => {
        setMenuOption(3);
        handleClose();

        navigate('/Contacto', {
            replace: true
        });
    }

    const onPerfil = () => {
        setMenuOption(4);
        handleClose();

        navigate('/Perfil', {
            replace: true
        });
    }

    const onMisPlanes = () => {
        setMenuOption(4);
        handleClose();

        navigate('/MisPlanes', {
            replace: true
        });
    }

    const onHistorial = () => {
        setMenuOption(4);
        handleClose();

        navigate('/Historial', {
            replace: true
        });
    }

    const onLogin = () => {
        setMenuOption(4);
        handleClose();

        navigate('/Login', {
            replace: true
        });
    }

    return (  
        <>
            <DropDownAccount 
                user={user}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                onPerfil={onPerfil}
                onMisPlanes={onMisPlanes}
                onHistorial={onHistorial}
                onLogout={onLogout}
                fromB64EncodeUnicode={fromB64EncodeUnicode}
            />

            <AppBar style={{ background: "#f5f5f5" }}>
                <Toolbar>
                    {
                        isMatch 
                        ? 
                            (
                                <>   
                                    <img src={Image} className='top-menu__icon-sm' alt='icon'/> 
                                    <Cart productsCart={productsCart} getProductsCart={getProductsCart} products={products}/>                   
                                    <DrawerComp 
                                        // user={user}
                                        onInicio={onInicio}
                                        onPlanes={onPlanes}
                                        onEmpresa={onEmpresa}
                                        onContacto={onContacto}
                                        onLogin={onLogin}
                                        onPerfil={onPerfil}
                                        onMisPlanes={onMisPlanes}
                                        onHistorial={onHistorial}
                                        onLogout={onLogout}
                                        fromB64EncodeUnicode={fromB64EncodeUnicode}
                                    />
                                </>
                            ) 
                        : 
                            (
                                <>
                                    <img src={Image} className='top-menu__icon-lg' alt='icon'/>
                                    <Tabs className='top-menu__options' value={menuOption}>
                                        <Tab label="Inicio" onClick={onInicio}/>
                                        <Tab label="Comprar" onClick={onPlanes}/>
                                        <Tab label="Empresas" onClick={onEmpresa}/>
                                        <Tab label="Contacto" onClick={onContacto}/>
                                        {
                                            user?.ssul === true
                                                ? <Tab label="Mi Cuenta" onClick={handleClick}/>
                                                : <Tab label="Ingresar" onClick={onLogin}/>                                 
                                        }
                                    </Tabs>
                                    <div className='top-menu__icons'>
                                        <a rel="noopener noreferrer" target='_blank' href='https://www.facebook.com/aurabienestarcr'>
                                            <img src={Facebook} alt='facebook'/>
                                        </a>
                                        <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/auraesbienestar'>
                                            <img src={Instagram} alt='instagram'/>
                                        </a>
                                        <Cart productsCart={productsCart} getProductsCart={getProductsCart} products={products} />
                                    </div>
                                </>
                            )
                    }
                </Toolbar>
            </AppBar>
        </>
    );
}

function DropDownAccount(props) {

    const { anchorEl, open, handleClose, onPerfil, onMisPlanes, onHistorial, onLogout, fromB64EncodeUnicode } = props;

    const user = JSON.parse(localStorage.getItem('uli_acr'));
    
    return (
        <>
            <DropDown
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                {
                    user?.ssul == true
                    ?   <DropDownItem><b>{fromB64EncodeUnicode(user?.uli)}</b></DropDownItem>
                    :   null
                }             
                <DropDownItem onClick={onPerfil}>Perfil</DropDownItem>
                <DropDownItem onClick={onMisPlanes}>Mis Planes</DropDownItem>
                <DropDownItem onClick={onHistorial}>Historial de Compras</DropDownItem>
                <DropDownItem onClick={onLogout}>Cerrar Sesión</DropDownItem>
            </DropDown> 
        </>    
    );
}