import React from 'react';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import useFetch from '../../hooks/use_fetch';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../utils/constants';
import Image from '../../assets/img/logos/LogoAzul.png';
import ImageBackground from '../../assets/img/content/Fondo1.jpg';
import Loading from '../../components/loading';

import Slider from '../../components/slider';
import Products from '../../components/products';
import Aura from '../../components/carnet_aura';
import Footer from '../../components/footer';

import './home.scss';

export default function Home(props) {

  const { addProductCart } = props;

  const favProducts = useFetch(URL_API_AURA + '/GetFavPlansToSell?clientSecret=' + FINAL_KEY_API_AURA, null);

  if(favProducts.loading || !favProducts.result)
  {
      return <Loading/>;
  }

  return (
    <>
      <Slider/>
      <div className='header'>
          <div className='header-background'>
            <img src={ImageBackground} alt='header-background__img'/>
          </div>
      </div>
      <Container className='body'>
        <div className='body-aura'>
          <img src={Image} alt='body-aura__img'/>
          <p>
            Aura es más que un plan de salud, es bienestar a tu alcance. 
            Por eso, además de asistencia en salud, te ofrecemos servicios integrales en Nutrición, 
            Psicología, asesoría financiera, atención de mascotas, asistencia en el hogar, 
            apoyo académico para los chicos, soporte técnico e incluso asistencia en carretera para cuando viajes, 
            porque el descanso es parte importante del bienestar.
          </p>
        </div>
      </Container>     
      <Products products={favProducts} addProductCart={addProductCart} />
      <Aura/>
      <Footer/>
      
      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
    </>  
  );
}
