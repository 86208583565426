import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../../../utils/constants';

import Loading from '../../../../components/loading';

import '../../account.scss';

export default function ForgotPassword() {

    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); 

    const navigate = useNavigate();

    const goLogin = () => {
        navigate('/Login', {
            replace: true
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setLoading(true);

        try {
            const res = await fetch(URL_API_AURA + '/ForgotPasswordAlternative?username=' + username + '&clientSecret=' + FINAL_KEY_API_AURA,
                {
                    method: 'POST'
                }
            );

            if(res.status == '200')
            {
                goLogin();
            }
            else
            {
                setError('* Ocurrió un error al intentar cambiar la contraseña.');
                setLoading(false);
            }                         
        }
        catch (e)
        {
            setError(e);
            setLoading(false);
        }
        finally
        {
            setLoading(false); 
        }
    }

    return (
        <>
            <Row>
                <Col xs={1} lg={3}></Col>
                
                <Col xs={12} lg={6}>
                    <div className="main">
                        <div className='main-card'>
                            <h3>Olvidé Contraseña</h3>

                            <p>Recibirás un correo electrónico con las indicaciones</p>

                            {
                                loading == true
                                ?   <Loading/>
                                :   <Form onSubmit={onSubmit} className="main-card__form">
                                        <label>Correo Electrónico</label>
                                        <input type="email" placeholder="Correo electrónico" required onChange={(e) => setUsername(e.target.value)} value={username}/>

                                        <Button type="submit">
                                            Cambiar
                                        </Button>
                                    </Form>
                            }

                            <p className="main-card__error">{error}</p>
                                                                            
                        </div>             
                    </div>
                </Col>
                
                <Col xs={1} lg={3}></Col>
            </Row>           
        </>
    );
}
