import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { STORAGE_PRODUCTS_CART } from '../../utils/constants';
import { countDuplicatesItemArray, removeArrayDuplicates, removeItemArray } from '../../utils/arrayFunc';
import { Link } from 'react-router-dom';

import { ReactComponent as CartEmpty } from '../../assets/img/cart/svg/cart-empty.svg';
import { ReactComponent as CartFull } from '../../assets/img/cart/svg/cart-full.svg';
import { ReactComponent as Close } from '../../assets/img/cart/svg/close.svg';
import { ReactComponent as Garbage } from '../../assets/img/cart/svg/garbage.svg';

import './cart.scss';

export default function Cart(props) {

    const { productsCart, getProductsCart, products } = props;
    
    const [cartOpen, setCartOpen] = useState(false);
    const widthCartContent = cartOpen ? 400 : 0;
    
    const [singleProductsCart, setSingleProductsCart] = useState([]);
    const [cartSubTotalPrice, setCartSubTotalPrice] = useState(0);
    const [cartIvaPrice, setCartIvaPrice] = useState(0);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);

    useEffect(() => {
        const allProductsId = removeArrayDuplicates(productsCart);
        setSingleProductsCart(allProductsId)
    }, [productsCart]);

    useEffect(() => {
        const productData = [];
        let totalPrice = 0;

        const allProductsId = removeArrayDuplicates(productsCart);
        
        allProductsId.forEach(productId => {
            const quantity = countDuplicatesItemArray(productId, productsCart);

            const productValue = {
                id: productId,
                quantity: quantity
            };

            productData.push(productValue);
        });

        if(!products.loading && products.result) {
            products.result.forEach(product => {
                productData.forEach(item => {
                    if(product.plan.id == item.id) {
                        const totalValue = product.plan.costo * item.quantity;
                        totalPrice = totalPrice + totalValue;
                    }                   
                });
            });
        }

        setCartSubTotalPrice(totalPrice);
        setCartIvaPrice(totalPrice * 0.13);
        setCartTotalPrice(totalPrice + (totalPrice * 0.13));
    }, [productsCart, products]);

    const openCart = () => {
        setCartOpen(true);
        document.body.style.overflow = 'hidden';
    }

    const closeCart = () => {
        setCartOpen(false);
        document.body.style.overflow = 'auto';
    }

    const emptyCart = () => {
        localStorage.removeItem(STORAGE_PRODUCTS_CART);
        getProductsCart();
    }

    const increaseQuantity = (id) => {
        const arrayItemsCart = productsCart;

        arrayItemsCart.push(id);
        localStorage.setItem(STORAGE_PRODUCTS_CART, arrayItemsCart);
        getProductsCart();
    }

    const decreaseQuantity = (id) => {
        const arrayItemsCart = productsCart;
        const result = removeItemArray(arrayItemsCart, id.toString());

        localStorage.setItem(STORAGE_PRODUCTS_CART, result);
        getProductsCart();
    }

    return (
        <>
            <div className='cart'>          
                <Button variant='link' className='cart-button'>
                    {productsCart.length > 0 
                        ? (<CartFull onClick={openCart} />)
                        : (<CartEmpty onClick={openCart} />)
                    }
                </Button>
                
                <p className={productsCart.length > 0 ? 'cart-quantity' : ''} >({productsCart.length})</p>
            </div>
            
            <div className='cart-content' style={{width: widthCartContent}}>
                <CartContentHeader closeCart={closeCart} emptyCart={emptyCart} productsCart={productsCart} />
                
                <div className='cart-content__products'>
                    {
                        productsCart.length > 0
                        ? (
                            singleProductsCart.map((idProductsCart, index) => (
                                <CartContentProducts
                                    key={index}
                                    products={products}
                                    idsProductsCart={productsCart}
                                    idProductCart={idProductsCart}
                                    increaseQuantity={increaseQuantity}
                                    decreaseQuantity={decreaseQuantity}
                                />
                            ))
                        )
                        : <p className='cart-content__products-empty'>No hay productos en el carrito...</p>                     
                    }
                </div>

                {
                    productsCart.length > 0 
                        ? (<CartContentFooter cartSubTotalPrice={cartSubTotalPrice} cartIvaPrice={cartIvaPrice} cartTotalPrice={cartTotalPrice} closeCart={closeCart} />)
                        : ''
                }                    
            </div>
        </>//De esta forma porque no necesito que se renderize ese contenedor, solo que me ayude a devolver
    );
}

function CartContentHeader(props) {
    const { closeCart, emptyCart, productsCart } = props;

    return(
        <div className='cart-content__header'>
            <div>
                <Close onClick={closeCart}/>
                <h2>Carrito</h2>
            </div>

            {
                productsCart.length > 0 
                    ? (
                        <Button variant='link' onClick={emptyCart}>
                            Vaciar
                            <Garbage/>
                        </Button>
                    )
                    : ''
            }          
        </div>
    );
}

function CartContentProducts(props) {
    const { 
        products: { loading, result },
        idsProductsCart,
        idProductCart,
        increaseQuantity,
        decreaseQuantity
    } = props;

    if(!loading && result) {
        return result.map((product, index) => {
            if(idProductCart == product.plan.id) {
                const quantity = countDuplicatesItemArray(product.plan.id, idsProductsCart);

                return (
                    <RenderProduct
                        key={index}
                        product={product}
                        quantity={quantity}
                        increaseQuantity={increaseQuantity}
                        decreaseQuantity={decreaseQuantity}
                    />
                )
            }
            else
            {
                return null;
            }
        });
    }
    else
    {
        return null;
    }
}

function RenderProduct(props) {
    const { product, quantity, increaseQuantity, decreaseQuantity } = props;

    return (
        <div className='cart-content__product'>
            <img src={product.plan.imagen} alt={product.plan.nombre} />
            <div className='cart-content__product-info'>
                <div>
                    <h3>{product.plan.nombre.substr(0, 35)}...</h3>
                    <p>${parseFloat(product.plan.costo).toFixed(2)} / mes</p>
                </div>
                <div>
                    <p>Cantidad: {quantity}</p>
                    <div>
                        <button onClick={() => increaseQuantity(product.plan.id)}><b>+</b></button>
                        <button onClick={() => decreaseQuantity(product.plan.id)}><b>-</b></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CartContentFooter(props) {
    const { cartSubTotalPrice, cartIvaPrice, cartTotalPrice, closeCart } = props;

    return (
        <div className='cart-content__footer'>
            <div>
                <p>Subtotal</p>
                <p>$ {cartSubTotalPrice.toFixed(2)}</p>
            </div>
            <div>
                <p>IVA</p>
                <p>$ {cartIvaPrice.toFixed(2)}</p>
            </div>
            <div>
                <p>Total</p>
                <p>$ {cartTotalPrice.toFixed(2)}</p>
            </div>
            
            <Link to={'/Resumen'}>
                <Button onClick={closeCart}>Pagar</Button>
            </Link>
        </div>
    );
}
