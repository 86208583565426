import React from 'react';
import { Carousel } from 'antd';

import './slider.scss';

export default function SliderMain() {
    return (
        <Carousel autoplay className='slider-main'>
            <Image image='https://botgevlc43usy.blob.core.windows.net/aurafiles/ImagenesAura/Slider.jpg'/>
            <Image image='https://botgevlc43usy.blob.core.windows.net/aurafiles/ImagenesAura/Slider1.jpg'/>
            <Image image='https://botgevlc43usy.blob.core.windows.net/aurafiles/ImagenesAura/Slider2.jpg'/>
            <Image image='https://botgevlc43usy.blob.core.windows.net/aurafiles/ImagenesAura/Slider6.jpg'/>
            <Image image='https://botgevlc43usy.blob.core.windows.net/aurafiles/ImagenesAura/Slider4.jpg'/>
            <Image image='https://botgevlc43usy.blob.core.windows.net/aurafiles/ImagenesAura/Slider5.jpg'/>          
        </Carousel>
    );
}

function Image(props) {
    
    const { image } = props;

    const cover = image;

    return (
        <div className='slider-main__image' style={{ backgroundImage: 'url(' + cover + ')' }}></div>
    );
}
