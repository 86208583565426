import React from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/use_fetch';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../utils/constants';
import AuraCarnet from '../../assets/img/content/AuraCarnet.PNG';
import AuraImg1 from '../../assets/img/content/Aura1.jpg';
import Loading from '../../components/loading';

import './carnet_aura.scss';

export default function CarnetAura() {

    const proveedores = useFetch(URL_API_AURA + '/GetProveedores?clientSecret=' + FINAL_KEY_API_AURA, null);

    if(proveedores.loading || !proveedores.result)
    {
        return <Loading/>;
    }

    return (
        <Container>
            <div className='AuraMain'>
                <Line1/>
                <LineProveedores proveedores={proveedores.result} />
                <Line2/>
            </div>
        </Container>       
    );
}

function Line1() {
    return (
        <Row className='AuraMain-Row1'>
            <Col xs={12} lg={6} className='colImg'>
                <img src={AuraCarnet} alt='AuraCarnet' />
            </Col>
            <Col xs={12} lg={6} className='colTxt'>
                <h2>Carnet de Descuentos</h2>
                <p>Con la adquisión de los Planes Aura cuentas con un beneficio extra exclusivo, nuestro carnet de descuentos.
                    Con este puedes disfrutar precios reducidos y ofertas únicas con toda nuestra  red de proveedores, por ejemplo,
                    en hospitales, gimnasios, farmacias, consultorios ¡Y mucho más!
                </p>
                <Link to='/Membership'>
                    <Button>
                        Beneficios Aura
                    </Button>
                </Link>
            </Col>
        </Row>
    );
}

function LineProveedores(props) {
    const { proveedores } = props;

    return (
        <Row className='AuraMain-RowProveedores'>
            { 
                !proveedores
                    ? null
                    : proveedores.map((item, index) => (
                        <ColImage key={index} imagen={item.imagen} />
                    ))
            }
        </Row>
    );
}

function ColImage(props) {
    const { imagen } = props;

    return (
        <Col xs={12} lg={2}>
            <img src={imagen} alt='ProveedorImg' />
        </Col>
    );
}

function Line2() {
    return (
        <Row className='AuraMain-Row2'>              
            <Col xs={12} lg={6} className='colTxt'>
                <h2>Tu Aura pone el bienestar a tu alcance</h2>

                <Row>
                    <Col xs={12} lg={12}>
                        <h5>• Aura te ofrece planes de beneficios</h5>
                        <p>Para tu bienestar y el de los tuyos ya que fueron pensados para satisfacer las necesidades de las familias. 
                            Puedes incluir a tus familiares y relativos sin exclusión por condiciones médicas o por edad. Ahí donde las
                            pólizas de seguro no llegan, Aura te cuida.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={12}>
                        <h5>• Cada uno de nuestros planes de salud y bienestar tienen características particulares</h5>
                        <p>Para responder a las necesidades de cada cliente, sin embargo, puede ser ajustados a tus preferencias.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={12}>
                        <h5>• Haz de tu salud y tus seres queridos una prioridad</h5>
                        <p>Aura tiene planes con coberturas para tus niños, tus mascotas, tus adultos mayores, tu salud física y mental,
                            te cubre en accidentes, brinda atención especializada para atletas y estudiantes, y estará ahí incluso en los
                            momentos más difíciles, con tu plan funerario.
                        </p>
                    </Col>
                </Row>                  
            </Col>
            <Col xs={12} lg={6} className='colImg'>
                <img src={AuraImg1} alt='AuraImg1' />
            </Col>
        </Row>
    );
}