import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../../utils/constants';

import Loading from '../../../components/loading';
import Footer from '../../../components/footer';

import './management.scss';

export default function Management() {

    const { idP, nameP } = useParams();

    //#region form
        const [email, setEmail] = useState('');
        const [id, setId] = useState('');
        const [firstName, setFirstName] = useState('');
        const [secondName, setSecondName] = useState('');
        const [firstLastName, setFirstLastName] = useState('');
        const [secondLastName, setSecondLastName] = useState('');
        const [birthday, setBirthday] = useState('');
        const [phone, setPhone] = useState('');
        const [gender, setGender] = useState(3);
    //#endregion form

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); 

    const [forOther, setForOther] = useState(false);

    const navigate = useNavigate();

    function planForOther() {
        if(forOther == true)
        {
            setForOther(false);
            setError(null);
        }
        else
        {
            setForOther(true);
            setError(null);
        }
    }

    const toMisPlanes = () => {
        navigate('/MisPlanes', {
            replace: true
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if(forOther == false)
        {
            try {
                const res = await fetch(URL_API_AURA + '/AssignPlanForMe?detalleCompraId=' + idP + '&clientSecret=' + FINAL_KEY_API_AURA,
                    {
                        method: 'POST'
                    }
                );
                
                if(res.status != 200)
                {
                    setError('Se ha producido un error al activar/asignar el plan');
                }
                else
                {
                    toMisPlanes();
                }          
            }
            catch (e)
            {
                setError('Se ha producido un error al intentar activar/asignar el plan');
            }
        }
        else
        {
            try {
                const res = await fetch(URL_API_AURA + '/AssignPlanForOther?detalleCompraId=' + idP + '&clientSecret=' + FINAL_KEY_API_AURA,
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(
                            {
                                Identificacion: id,                             
                                PrimerNombre: firstName,
                                SegundoNombre: secondName,
                                PrimerApellido: firstLastName,
                                SegundoApellido: secondLastName,
                                FechaNacimiento: birthday,
                                Email: email,
                                GeneroId: gender
                            }
                        )
                    }
                );
                
                if(res.status != 200)
                {
                    setError('Se ha producido un error al activar/asignar el plan');
                }
                else
                {
                    toMisPlanes();
                }            
            }
            catch (e)
            {
                setError('Se ha producido un error al intentar activar/asignar el plan');
            }
        }

        setLoading(false);
    }

    return (
        <>
            <Row>
                <Col xs={1} lg={2}></Col>
                
                <Col xs={12} lg={8}>
                    <div className="main_management">
                        <div className='main_management-card'>
                            <h3>Gestionar Mi Plan: <b>{nameP}</b></h3>

                            <div className="main_management-card__form">
                                
                                <PlanForWho planForOther={planForOther} />
                  
                                {
                                    loading == true
                                    ? <Loading/>
                                    : forOther == false
                                        ?   <Button onClick={onSubmit}>
                                                Activar / Asignar
                                            </Button>
                                        :   <FormForOther
                                                setEmail={setEmail}
                                                setId={setId}
                                                setFirstName={setFirstName}
                                                setSecondName={setSecondName}
                                                setFirstLastName={setFirstLastName}
                                                setSecondLastName={setSecondLastName}
                                                setBirthday={setBirthday}
                                                setGender={setGender}
                                                email={email}
                                                id={id}
                                                firstName={firstName}
                                                secondName={secondName}
                                                firstLastName={firstLastName}
                                                secondLastName={secondLastName}
                                                birthday={birthday}
                                                gender={gender}
                                                onSubmit={onSubmit}
                                            />
                                }
                            </div>

                            <p className="main-card__error">{error}</p>
                                                                           
                        </div>             
                    </div>
                </Col>
                
                <Col xs={1} lg={2}></Col>
            </Row>

            <Footer/>          
        </>
    );
}

function PlanForWho(props) {

    const { planForOther } = props;

    return (
        <>
            <Row>
                <Col xs={2} lg={1}>
                    <input type="checkbox" onClick={planForOther} />
                </Col>
                <Col xs={10} lg={11}>
                    <label>Este plan no es para mí, deseo cederlo (Siempre tendrás el control del plan pero cedes el uso, beneficios y coberturas del mismo, puedes asignarlo a tus familiares, amigos o mascotas).</label>
                </Col>
            </Row>
            {/* <Row>
                <Col xs={2} lg={1}>
                    <input type="checkbox" onClick={planForOther} />
                </Col>
                <Col xs={10} lg={11}>
                    <label>Este plan no es para mí, deseo cederlo (Siempre tendrás el control del plan pero cedes el uso, beneficios y coberturas del mismo, puedes asignarlo a tus familiares, amigos o mascotas).</label>
                </Col>
            </Row> */}
        </>
    );
}

function FormForOther(props) {

    const { setEmail, setId, setFirstName, setSecondName, setFirstLastName, setSecondLastName, setBirthday, setGender, 
        email, id, firstName, secondName, firstLastName, secondLastName, birthday, gender, onSubmit } = props;

    return (
        <>
            <Form onSubmit={onSubmit} className="main_management-card__form">
                <Row>
                    <Col  xs={12} lg={6}>
                        <label>Correo Electrónico</label>
                        <input type="email" placeholder="Correo electrónico" onChange={(e) => setEmail(e.target.value)} value={email}/>
                    </Col>
                    <Col  xs={12} lg={6}>
                        <label>Identificación</label>
                        <input type="text" placeholder="Identificación" required onChange={(e) => setId(e.target.value)} value={id}/>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} lg={6}>
                        <label>Primer Nombre</label>
                        <input type="text" placeholder="Primer Nombre" required onChange={(e) => setFirstName(e.target.value)} value={firstName}/>
                    </Col>
                    <Col  xs={12} lg={6}>
                        <label>Segundo Nombre</label>
                        <input type="text" placeholder="Segundo Nombre" onChange={(e) => setSecondName(e.target.value)} value={secondName}/>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} lg={6}>
                        <label>Primer Apellido</label>
                        <input type="text" placeholder="Primer Apellido" required onChange={(e) => setFirstLastName(e.target.value)} value={firstLastName}/>
                    </Col>
                    <Col  xs={12} lg={6}>
                        <label>Segundo Apellido</label>
                        <input type="text" placeholder="Segundo Apellido" onChange={(e) => setSecondLastName(e.target.value)} value={secondLastName}/>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} lg={6}>
                        <label>Fecha Nacimiento</label>
                        <input type="date" placeholder="Fecha Nacimiento" required onChange={(e) => setBirthday(e.target.value)} value={birthday}/>
                    </Col>
                    <Col  xs={12} lg={6}>
                        <label>Género</label>
                        {/* <input type="text" placeholder="Género" required onChange={(e) => setGender(e.target.value)} value={gender}/> */}
                        <select onChange={(e) => setGender(e.target.value)} value={gender}>
                            <option value='1'>Masculino</option>
                            <option value='2'>Femenino</option>
                            <option value='3'>Otro</option>
                        </select>
                    </Col>
                </Row>
                
                <Button type="submit">
                    Activar / Asignar
                </Button>    
            </Form>
        </>
    );
}