import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { toast } from 'react-toastify';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import useFetch from './hooks/use_fetch';
import { URL_API_AURA, FINAL_KEY_API_AURA, STORAGE_PRODUCTS_CART } from './utils/constants';

import Menu from './components/menu/menu';
import Whatsapp from './components/whatsapp';

//Pages
import Home from './pages/home';
import Planes from './pages/planes';
import Plan from './pages/plan';
import Membership from './pages/membership';
import Empresa from './pages/empresa';
import Contacto from './pages/contacto';
import Perfil from './pages/account/profile/view';
import EditarPerfil from './pages/account/profile/edit';
import MisPlanes from './pages/account/my_plans';
import Gestion from './pages/account/management';
import Historial from './pages/account/purchases';
import Resumen from './pages/cart';
import Payment from './pages/payment';
import Error404 from './pages/error404';

//Account
import Login from './auth/pages/account/login';
import NewUser from './auth/pages/account/create';
import ForgotPass from './auth/pages/account/password';
import ChangePassword from './auth/pages/account/changePassword';
import RoutePrivate from './auth/pages/account/lockRoute/routePrivate';
import RoutePublic from './auth/pages/account/lockRoute/routePublic';

import './App.scss';

export default function App() {
  
  const { Content } = Layout;

  const products = useFetch(URL_API_AURA + '/GetPlansToSell?clientSecret=' + FINAL_KEY_API_AURA, null);

  const [productsCart, setProductCart] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    getProductsCart();
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  const getProductsCart = () => {
    const idsProducts = localStorage.getItem(STORAGE_PRODUCTS_CART);

    if(idsProducts) {
      const idsProductsSplit = idsProducts.split(',');
      setProductCart(idsProductsSplit);
    } else {
      setProductCart([]);
    }
  }

  const addProductCart = (id, name) => {
    const idsProducts = productsCart;
    idsProducts.push(id);
    setProductCart(idsProducts);
    localStorage.setItem(STORAGE_PRODUCTS_CART, productsCart);

    getProductsCart();
    toast.success(`${name} añadido al carrito correctamente`);
  }

  const getUser = () => {
    setUser(JSON.parse(localStorage.getItem('uli_acr')));
  }

  const login = (user) => {
    localStorage.setItem('uli_acr', JSON.stringify(user));

      setUser({
        ssul: true,
        uli: user
    });
  }

  const logout = () => {
    localStorage.removeItem('uli_acr');
    localStorage.removeItem('lpi_acr');
    
    setUser(null);
  }

  return (
    <div>
      <Layout>
        <Router>
          <Menu productsCart={productsCart} getProductsCart={getProductsCart} products={products} user={user} logout={logout} />

          <Content>
            <Routes>
              <Route path='/' element={<Home addProductCart={addProductCart} />} />
        
              <Route path='/Planes' element={<Planes addProductCart={addProductCart} />}/>
              <Route path='/Plan/:id' element={<Plan addProductCart={addProductCart} />}/>
              <Route path='/Membership' element={<Membership/>}/>
              <Route path='/Empresa' element={<Empresa/>}/>
              <Route path='/Contacto' element={<Contacto/>}/>
              
              <Route path='/Perfil/*' element={
                <RoutePrivate
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<Perfil/>}/>
                    </Routes>
                  }
                />
              }/>
              <Route path='/EditarPerfil/*' element={
                <RoutePrivate
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<EditarPerfil/>}/>
                    </Routes>
                  }
                />
              }/>
              <Route path='/MisPlanes/*' element={
                <RoutePrivate
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<MisPlanes/>}/>
                    </Routes>
                  }
                />
              }/>
              <Route path='/Gestion/:idP/:nameP' element={
                <RoutePrivate
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<Gestion/>}/>
                    </Routes>
                  }
                />
              }/>
              <Route path='/Historial/*' element={
                <RoutePrivate
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<Historial/>}/>
                    </Routes>
                  }
                />
              }/>
             
              <Route path='/Login/*' element={
                <RoutePublic
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<Login login={login} /> }/>
                    </Routes>
                  } 
                />
              }/>
              <Route path='/Account/*' element={
                <RoutePublic 
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<NewUser/>}/>
                    </Routes>
                  }
                />
              }/>
              <Route path='/ForgotPassword/*' element={
                <RoutePublic 
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<ForgotPass/>}/>
                    </Routes>
                  }
                />
              }/>

              <Route path='/ChangePassword/:token/:username'element={<ChangePassword/>}/>
              
              <Route path='/Resumen' element={<Resumen productsCart={productsCart} getProductsCart={getProductsCart} products={products}/>}/>
              <Route path='/Pago/*' element={
                <RoutePrivate
                  user={user}
                  route={ 
                    <Routes>
                      <Route path='/*' element={<Payment productsCart={productsCart} getProductsCart={getProductsCart} products={products} />}/> 
                    </Routes>
                  }
                />
              }/>

              <Route path='*' element={<Error404/>} />
            </Routes>
          </Content>
        </Router>
      </Layout>
      <Whatsapp/>
    </div>
  );
}
