import React from 'react';
import useFetch from '../../hooks/use_fetch';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../utils/constants';
import Loading from '../../components/loading';

import Footer from '../../components/footer';

import './plan.scss';

export default function Plan(props) {

    const { addProductCart } = props;

    const { id } = useParams();

    const product = useFetch(URL_API_AURA + '/GetPlanBeneficiosPlan?planId=' + id + '&clientSecret=' + FINAL_KEY_API_AURA, null);

    if(product.loading || !product.result)
    {
        return <Loading/>;
    }

    return (
        <div className='background-plan'>       
            <Container>
                <div className='product-plan'>
                    <HeaderProduct product={product.result} addProductCart={addProductCart} />
                    <BodyProduct product={product.result} />
                </div>
            </Container>

            <Footer/>
            <ToastContainer
                position='bottom-right'
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange={false}
                draggable
                pauseOnHover={false}
            />
        </div>
    );
}

function HeaderProduct(props) {
    const {
        product: { plan, listFavBeneficioPlan },
        addProductCart
    } = props;

    return (
        <Row className='product-plan__header'>                                   
            <Col xs={12} lg={6} className='product-plan__header-info'>
                <h1>{plan.nombre}</h1>
                <h3>${plan.costo} / mes</h3>
                <div>
                    { 
                        !listFavBeneficioPlan
                            ? null
                            : listFavBeneficioPlan.map((item, index) => (
                                <LineResumeProduct key={index} beneficio={item.proveedorBeneficio.beneficio.nombre} />
                            ))
                    }
                </div>
                <Button onClick={() => addProductCart(plan.id, plan.nombre)}>
                    Añadir al carrito
                </Button>
                <a rel="noopener noreferrer" target='_blank' href={plan.condiciones}>Ver términos y condiciones</a>
            </Col>
            <Col xs={12} lg={6} className='product-plan__header-image'>
                <img src={plan.imagen} />
            </Col>
        </Row>
    );
}

function LineResumeProduct(props) {
    const { beneficio } = props;

    return (
        <p><b>•</b> { beneficio }</p>
    );
}

function BodyProduct(props) {
    const {
        product: { plan, listBeneficioPlan }
    } = props;

    return (
        <div className='product-plan__body'>
            <h1>Descripción</h1>
            
            <Row className='product-plan__body-list'>
                <Col xs={8} lg={8}>
                    <h5>{plan.nombre.toUpperCase()}</h5>
                </Col>
                <Col xs={4} lg={4}>
                    <h5>COSTO ${plan.costo} + IVA MENSUALMENTE</h5> 
                </Col>
            </Row>

            <Row className='product-plan__body-list__header'>
                <Col xs={8} lg={8}>
                    <h6>SERVICIOS DE ASISTENCIA</h6>
                </Col>
                <Col xs={4} lg={4}>
                    <h6>LÍMITE EN EL COSTO - EVENTOS</h6>
                </Col>
            </Row>

            <Row className='product-plan__body-list__item'>
                <Col xs={12} lg={12}>
                    { 
                        !listBeneficioPlan
                            ? null
                            : listBeneficioPlan.map((item, index) => (
                                <LineProduct key={index} monto={item.monto} beneficio={item.proveedorBeneficio.beneficio} />
                            ))
                    }
                </Col>
            </Row>
        </div>
    );
}

function LineProduct(props) {
    const { monto, beneficio } = props;

    return (
        <Row className='product-plan__body-list__item-row'>
            <Col xs={8} lg={8}>
                <p className='category'>{beneficio.tipoBeneficio.nombre}</p>
                <p>{beneficio.nombre}</p>
            </Col>
            <Col xs={4} lg={4}>
                <p>{monto}</p>
            </Col>
        </Row>
    );
}