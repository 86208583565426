import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from '../../../assets/img/logos/LogoAzul.png';

import './successful.scss';

export default function Successful(props) {

    const { TransactionId } = props;

    return (
        <div className='successful'>
            <img src={Image} className='iconAura' alt='iconAura'/>
            <h4>¡Muchas gracias!</h4>
            <h5>Tu compra se ha realizado con éxito, te enviaremos el comprobante al correo electrónico.</h5>
            <h5>Número de transacción: {TransactionId}</h5>

            <Link to='/Planes'>
                <Button type='primary'>
                    Ver más
                </Button>
            </Link>
        </div>
    );
}