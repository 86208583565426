import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { STORAGE_PRODUCTS_CART } from '../../utils/constants';
import { countDuplicatesItemArray, removeArrayDuplicates, removeItemArray } from '../../utils/arrayFunc';
import Loading from '../../components/loading';

import { ReactComponent as Garbage } from '../../assets/img/cart/svg/garbage.svg';

import Footer from '../../components/footer';

import './cart.scss';

export default function Cart(props) {
    
    const { productsCart, getProductsCart, products } = props;

    const [singleProductsCart, setSingleProductsCart] = useState([]);
    const [cartSubTotalPrice, setCartSubTotalPrice] = useState(0);
    const [cartIvaPrice, setCartIvaPrice] = useState(0);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);

    useEffect(() => {
        const allProductsId = removeArrayDuplicates(productsCart);
        setSingleProductsCart(allProductsId)
    }, [productsCart]);

    useEffect(() => {
        const productData = [];
        let totalPrice = 0;

        const allProductsId = removeArrayDuplicates(productsCart);
        
        allProductsId.forEach(productId => {
            const quantity = countDuplicatesItemArray(productId, productsCart);
            
            const productValue = {
                id: productId,
                quantity: quantity
            };

            productData.push(productValue);
        });

        if(!products.loading && products.result) {
            products.result.forEach(product => {
                productData.forEach(item => {
                    if(product.plan.id == item.id) {
                        const totalValue = product.plan.costo * item.quantity;
                        totalPrice = totalPrice + totalValue;
                    }                   
                });
            });
        }

        setCartSubTotalPrice(totalPrice);
        setCartIvaPrice(totalPrice * 0.13);
        setCartTotalPrice(totalPrice + (totalPrice * 0.13));
    }, [productsCart, products]);

    const emptyCart = () => {
        localStorage.removeItem(STORAGE_PRODUCTS_CART);
        getProductsCart();
    }

    const increaseQuantity = (id) => {
        const arrayItemsCart = productsCart;

        arrayItemsCart.push(id);
        localStorage.setItem(STORAGE_PRODUCTS_CART, arrayItemsCart);
        getProductsCart();
    }

    const decreaseQuantity = (id) => {
        const arrayItemsCart = productsCart;
        const result = removeItemArray(arrayItemsCart, id.toString());

        localStorage.setItem(STORAGE_PRODUCTS_CART, result);
        getProductsCart();
    }

    if(products.loading || !products.result)
    {
        return <Loading/>;
    }

    return (
        <>
            <Container>
                <Row className='resume'>
                    <Resume
                        singleProductsCart={singleProductsCart}
                        products={products}
                        productsCart={productsCart}
                        emptyCart={emptyCart}
                        increaseQuantity={increaseQuantity}
                        decreaseQuantity={decreaseQuantity}
                        />
                    <ResumeTotal cartSubTotalPrice={cartSubTotalPrice} cartIvaPrice={cartIvaPrice} cartTotalPrice={cartTotalPrice} />
                </Row>
            </Container>
            <Footer/>
        </>
    );
}

function Resume(props) {
    const { singleProductsCart, products, productsCart, emptyCart, increaseQuantity, decreaseQuantity } = props;

    return (
        <Col xs={12} lg={8}>
            <h2>Resumen del carrito</h2>
            
            <ResumeHeader/>
            <ResumeContent
                singleProductsCart={singleProductsCart}
                products={products}
                productsCart={productsCart}
                increaseQuantity={increaseQuantity}
                decreaseQuantity={decreaseQuantity}
            />

            <div className='resume-clear'>
                {
                    productsCart.length > 0 
                        ? (
                            <Button variant='link' onClick={emptyCart}>
                                Vaciar
                                <Garbage/>
                            </Button>
                        )
                        : ''
                }
            </div>
        </Col>
    );
}

function ResumeHeader() {
    return (
        <div className='resume-header'>
            <Row>
                <Col xs={4} lg={4}>
                    <h6>Plan</h6>
                </Col>
                <Col xs={2} lg={2}>
                    <h6>Precio</h6>
                </Col>
                <Col xs={2} lg={2}>
                    <h6>Cantidad</h6>
                </Col>
                <Col xs={2} lg={2}>
                    <h6>Subtotal</h6>
                </Col>
                <Col xs={2} lg={2}>
                </Col>
            </Row>
        </div>
    );
}

function ResumeContent(props) {
    const { singleProductsCart, products, productsCart, increaseQuantity, decreaseQuantity } = props;

    return (
        <div className='resume-content'>
            {
                (
                    singleProductsCart.map((idProductsCart, index) => (
                        <ResumeLine
                            key={index}
                            products={products}
                            idsProductsCart={productsCart}
                            idProductCart={idProductsCart}
                            increaseQuantity={increaseQuantity}
                            decreaseQuantity={decreaseQuantity}
                        />
                    ))
                )                    
            }
        </div>
    );
}

function ResumeLine(props) {
    const { 
        products: { loading, result },
        idsProductsCart,
        idProductCart,
        increaseQuantity,
        decreaseQuantity
    } = props;

    if(!loading && result) {
        return result.map((product, index) => {
            if(idProductCart == product.plan.id) {
                const quantity = countDuplicatesItemArray(product.plan.id, idsProductsCart);

                return (
                    <RenderContentLine
                        key={index}
                        product={product}
                        quantity={quantity}
                        increaseQuantity={increaseQuantity}
                        decreaseQuantity={decreaseQuantity}
                    />
                )
            }
            else
            {
                return null;
            }
        });
    }
    else
    {
        return null;
    }
}

function RenderContentLine(props) {
    const { product, quantity, increaseQuantity, decreaseQuantity } = props;

    return (
        <Row className='resume-content__line'>
            <Col className='resume-content__line-obj' xs={4} lg={4}>
                <img src={product.plan.imagen} alt={product.plan.nombre} />
                <p>{product.plan.nombre}</p>
            </Col>
            <Col xs={2} lg={2}>
                <p>${parseFloat(product.plan.costo).toFixed(2)} / mes</p>
            </Col>
            <Col xs={2} lg={2}>
                <p>{quantity}</p>
            </Col>
            <Col xs={2} lg={2}>
                <p>${parseFloat(product.plan.costo * quantity).toFixed(2)}</p>
            </Col>           
            <Col xs={2} lg={2}>
                <div>
                    <button onClick={() => increaseQuantity(product.plan.id)}><b>+</b></button>
                    <button onClick={() => decreaseQuantity(product.plan.id)}><b>-</b></button>
                </div>
            </Col>
        </Row>
    );
}

function ResumeTotal(props) {
    const { cartSubTotalPrice, cartIvaPrice, cartTotalPrice } = props;

    return (
        <Col xs={12} lg={4}>
            <h2>Total del carrito</h2>

            <div className='resume-total'>
                <div>
                    <h5>Subtotal</h5>
                    <p>$ {cartSubTotalPrice.toFixed(2)}</p>
                </div>
                <div>
                    <h5>IVA</h5>
                    <p>$ {cartIvaPrice.toFixed(2)}</p>
                </div>
                <div>
                    <h5>Total</h5>
                    <p>$ {cartTotalPrice.toFixed(2)}</p>
                </div>
                
                {/* {
                    cartTotalPrice > 0
                        ?   <Link to={'/Pago'}>
                                <Button>Finalizar compra</Button>
                            </Link>
                        :   null
                } */}
                
            </div>
        </Col>
    );
}
