import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Facebook from '../../../assets/img/logos/Facebook.png';
import Instagram from '../../../assets/img/logos/Instagram.png';

import './drawer.scss';

export default function DrawerComp(props) {

    const { onInicio, onPlanes, onEmpresa, onContacto, onLogin, onPerfil, onMisPlanes, onHistorial, onLogout, fromB64EncodeUnicode } = props;

    const [openDrawer, setOpenDrawer] = useState(false);

    const user = JSON.parse(localStorage.getItem('uli_acr'));

    return (
        <>
            <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List>
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={onInicio}>Inicio</ListItemText>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={onPlanes}>Comprar</ListItemText>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={onEmpresa}>Empresas</ListItemText>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={onContacto}>Contacto</ListItemText>
                        </ListItemIcon>
                    </ListItemButton>
                    {
                        user?.ssul === true
                            ?   <>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <ListItemText><b>{fromB64EncodeUnicode(user?.uli)}</b></ListItemText>
                                        </ListItemIcon>
                                    </ListItemButton>
                                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                                        <ListItemIcon>
                                            <ListItemText onClick={onPerfil}>Perfil</ListItemText>
                                        </ListItemIcon>
                                    </ListItemButton>
                                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                                        <ListItemIcon>
                                            <ListItemText onClick={onMisPlanes}>Mis Planes</ListItemText>
                                        </ListItemIcon>
                                    </ListItemButton>
                                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                                        <ListItemIcon>
                                            <ListItemText onClick={onHistorial}>Historial de Compras</ListItemText>
                                        </ListItemIcon>
                                    </ListItemButton>
                                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                                        <ListItemIcon>
                                            <ListItemText onClick={onLogout}>Cerrar Sesión</ListItemText>
                                        </ListItemIcon>
                                    </ListItemButton>
                                </>                                 
                            :   <ListItemButton onClick={() => setOpenDrawer(false)}>
                                    <ListItemIcon>
                                        <ListItemText onClick={onLogin}>Ingresar</ListItemText>
                                    </ListItemIcon>
                                </ListItemButton>
                    }
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText>
                                <a rel="noopener noreferrer" target='_blank' href='https://www.facebook.com/aurabienestarcr'>
                                    <img src={Facebook} className='menu__icon' alt='facebook'/>
                                </a>
                            </ListItemText>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText>
                                <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/auraesbienestar'>
                                    <img src={Instagram} className='menu__icon' alt='instagram'/>
                                </a>
                            </ListItemText>
                        </ListItemIcon>
                    </ListItemButton>
                </List>
            </Drawer>
            <IconButton sx={{ color: "gray", marginLeft: "auto" }} onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon/>
            </IconButton>
        </>
    );
}