import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import Image from '../../assets/img/content/Empresas.jpg';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormContact from '../../components/form_contact';
import Footer from '../../components/footer';

import './empresa.scss';

export default function Empresa() {
    return (
        <>
            <Container>
                <div className='empresa'>
                    <EmpresaHeader/>
                    <EmpresaBody/>
                    {/* <EmpresaFooter/> */}
                    <FormContact/>
                </div>
            </Container>
            <Footer/>
            <ToastContainer
                position='bottom-right'
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange={false}
                draggable
                pauseOnHover={false}
            />  
        </>
    );
}

function EmpresaHeader() {
    return (
        <div className='empresa-header'>
            <h1>Empresas</h1>
            <h5>PLANES A LA MEDIDA DE TUS COLABORADORES</h5>
            <p>Sabemos que cada equipo es diferente, algunos son más boomer, otros son más millennials y otros
                una mezcla de cada generación, por eso es importante tener la posibilidad de crear un plan que
                se adapte a cada una de sus necesidades.
            </p>
            <p>Planes Aura para empresas te permite personalizar cada detalle del plan que le quieres ofrecer
                a tus colaboradores, puedes elegir coberturas y armar tu propio paquete.
            </p>
        </div>
    );
}

function EmpresaBody() {
    return (
        <div className='empresa-body'>
            <Row>
                <Col xs='12' lg='6'>
                    <Row className='info-row'>
                        <Col xs='12' lg='12'>   
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <h4>¿Por qué elegir Planes Aura para tu empresa?</h4>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>Para recibir cobertura personalizada junto a los mejores proveedores del país.
                                        Al invertir en bienestar físico y emocional, se logra un retorno de en salud
                                        y productividad.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className='info-row'>
                        <Col xs='12' lg='12'>                       
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <h4>¿Cómo impacta Planes Aura a tus colaboradores?</h4>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>El tener persona sano, tranquilo, seguro de que sus seres queridos están 
                                        cubiertos genera una mayor productividad y enfoque sobre sus tareas diarias.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className='info-row'>
                        <Col xs='12' lg='12'>                         
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <h4>¿Cómo elegir el mejor plan para mi empresa?</h4>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>Los planes Aura son muy flexibles, por lo que podemos hacer una campaña 
                                        inicial de estadística para sondear que puede estar necesitando su población, 
                                        tomar  en cuenta que año a año podemos analizar las coberturas e ir 
                                        actualizando.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className='info-row'>
                        <Col xs='12' lg='12'>                           
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <h4>¿Qué costo tiene por colaborador?</h4>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>Todos los planes son personalizados por lo que su costo varia, pero puedes 
                                        obtener planes colectivos desde los $6.00.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                </Col>
                <Col xs='12' lg='6'>
                    <img src={Image} alt='empresa-img'/>
                </Col>
            </Row>
        </div>
    );
}

// function EmpresaFooter() {
//     return (
//         <div className='empresa-footer'>
//             <h1>Esto es lo que Aura te ofrece</h1>
//             <p>Planes Aura para empresas te permite personalizar cada detalle del plan que le quieres ofrecer
//                 a tus colaboradores, puedes elegir coberturas y armar tu propio paquete.
//             </p>
//         </div>
//     );
// }
