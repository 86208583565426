import React from 'react';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import useFetch from '../../hooks/use_fetch';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../utils/constants';
import Loading from '../../components/loading';

import Products from '../../components/products';
import Footer from '../../components/footer';

import './planes.scss';

export default function Planes(props) {

    const { addProductCart } = props;

    const products = useFetch(URL_API_AURA + '/GetPlansToSell?clientSecret=' + FINAL_KEY_API_AURA, null);

    if(products.loading || !products.result)
    {
        return <Loading/>;
    }

    return (
        <>
            {/* <div className='contacto-background'>
                <img src={Background} alt='contacto-background' />
            </div> */}
            <Container>
                <Products products={products} addProductCart={addProductCart} />    
            </Container>
            <ToastContainer
                position='bottom-right'
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange={false}
                draggable
                pauseOnHover={false}
            />
            <Footer/>
        </>
        
    );
}