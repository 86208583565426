import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import useFetch from '../../../../hooks/use_fetch';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../../../utils/constants';

import Loading from '../../../../components/loading';
import Footer from '../../../../components/footer';

import '../../../../auth/pages/account.scss';

export default function View() {

    const user = JSON.parse(localStorage.getItem('uli_acr'));

    const accountInformation = useFetch(URL_API_AURA + '/AccountInformation?username=' + user.uli + '&clientSecret=' + FINAL_KEY_API_AURA, null);

    if(accountInformation.loading || !accountInformation.result)
    {
        return <Loading/>;
    }

    return (
        <>
            <Row>
                <Col xs={1} lg={2}></Col>
                
                <Col xs={12} lg={8}>
                    <div className="main">
                        <div className='main-card'>
                            <h3>Mi Perfil</h3>

                            <Form className="main-card__form">
                                <Row>
                                    <Col xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Correo Electrónico</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.email}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                    <Col  xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Identificación</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.identificacion}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                    <Col  xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Primer Nombre</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.primerNombre}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                </Row>
                                <Row>                                   
                                    <Col  xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Segundo Nombre</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.segundoNombre}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Primer Apellido</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.primerApellido}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                    <Col  xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Segundo Apellido</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.segundoApellido}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Fecha Nacimiento (Día/Mes/Año)</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{moment(accountInformation.result.fechaNacimiento).format('DD-MM-YYYY')}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Género</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.genero.nombre}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <label><b>Teléfono</b></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{accountInformation.result.telefonoDomicilio}</label>
                                            </Col>
                                        </Row> 
                                    </Col>
                                </Row>

                                <Link to={'/EditarPerfil'}>
                                    <Button>Editar</Button>
                                </Link>
                            </Form>                                            
                        </div>             
                    </div>
                </Col>
                
                <Col xs={1} lg={2}></Col>
            </Row>

            <Footer/>         
        </>
    );
}