import React from 'react';
import useFetch from '../../hooks/use_fetch';
import { Container, Col, Row } from 'react-bootstrap';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../utils/constants';
import Loading from '../../components/loading';

import Footer from '../../components/footer';

import './membership.scss';

export default function Membership() {

    const product = useFetch(URL_API_AURA + '/GetPlanBeneficiosPlan?planId=' + 0 + '&clientSecret=' + FINAL_KEY_API_AURA, null);

    if(product.loading || !product.result)
    {
        return <Loading/>;
    }

    return (
        <div className='background-plan'>       
            <Container>
                <div className='product-plan'>
                    <HeaderProduct product={product.result} />
                    <BodyProduct product={product.result} />
                </div>
            </Container>

            <Footer/>
        </div>
    );
}

function HeaderProduct(props) {
    const {
        product: { plan, listFavBeneficioPlan }
    } = props;

    return (
        <Row className='product-plan__header'>                                   
            <Col xs={12} lg={6} className='product-plan__header-info'>
                <h1>{plan.nombre}</h1>
                <h3>Gratuito por la compra de cualquier plan</h3>
                <div>
                    { 
                        !listFavBeneficioPlan
                            ? null
                            : listFavBeneficioPlan.map((item, index) => (
                                <LineResumeProduct key={index} beneficio={item.proveedorBeneficio.beneficio.nombre} />
                            ))
                    }
                </div>
            </Col>
            <Col xs={12} lg={6} className='product-plan__header-image'>
                <img src={plan.imagen} alt='header-img'/>
            </Col>
        </Row>
    );
}

function LineResumeProduct(props) {
    const { beneficio } = props;

    return (
        <p><b>•</b> { beneficio }</p>
    );
}

function BodyProduct(props) {
    const {
        product: { plan, listBeneficioPlan }
    } = props;

    return (
        <div className='product-plan__body'>
            <h1>Descripción</h1>
            
            <Row className='product-plan__body-list'>
                <Col xs={7} lg={7}>
                    <h5>{plan.nombre.toUpperCase()}</h5>
                </Col>
                <Col xs={3} lg={3}>
                    <h5>GRATUITO</h5> 
                </Col>
                <Col xs={2} lg={2}>
                    {/* <h5></h5>  */}
                </Col>
            </Row>

            <Row className='product-plan__body-list__header'>
                <Col xs={7} lg={7}>
                    <h6>SERVICIOS DE ASISTENCIA</h6>
                </Col>
                <Col xs={3} lg={3}>
                    <h6>LÍMITE EN EL COSTO - EVENTOS</h6>
                </Col>
                <Col xs={2} lg={2}>
                    {/* <h6></h6> */}
                </Col>
            </Row>

            <Row className='product-plan__body-list__item'>
                <Col xs={12} lg={12}>
                    { 
                        !listBeneficioPlan
                            ? null
                            : listBeneficioPlan.map((item, index) => (
                                <LineProduct key={index} monto={item.monto} beneficio={item.proveedorBeneficio.beneficio} imagen={item.proveedorBeneficio.proveedor.imagen} />
                            ))
                    }
                </Col>
            </Row>
        </div>
    );
}

function LineProduct(props) {
    const { monto, beneficio, imagen } = props;

    return (
        <Row className='product-plan__body-list__item-row'>
            <Col xs={7} lg={7}>
                <p className='category'>{beneficio.tipoBeneficio.nombre}</p>
                <p>{beneficio.nombre}</p>
            </Col>
            <Col xs={3} lg={3}>
                <p>{monto}</p>
            </Col>
            <Col xs={12} lg={2} className='imgContainer'>
                <img src={imagen} alt='imgContainer'/>
            </Col>
        </Row>
    );
}
