import React from 'react';
import { Button } from 'react-bootstrap';
import useFetch from '../../../hooks/use_fetch';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../../utils/constants';

import Loading from '../../../components/loading';
import Footer from '../../../components/footer';

import './purchases.scss';

export default function Purchases() {

    const user = JSON.parse(localStorage.getItem('uli_acr'));

    const purchases = useFetch(URL_API_AURA + '/Purchases?username=' + user.uli + '&clientSecret=' + FINAL_KEY_API_AURA, null);
    
    if(purchases.loading || !purchases.result)
    {
        return <Loading/>;
    }

    return (
        <>  
            <div className='main_purchases'>
                <div className='main_purchases-card'>
                    <h3>Historial de Compras</h3>

                    <div className='main_purchases-card__content'>
                        <div className='main_purchases-card__content-header'>
                            <div className='main_purchases-card__content-header__col'>
                                Id
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Plan
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Costo
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Cantidad
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Subtotal
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Transacción
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Fecha Compra
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Último Pago
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Próximo Pago
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Requiere (FE)
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Pago Automático
                            </div>
                            <div className='main_purchases-card__content-header__col'>
                                Tarjeta
                            </div>
                        </div>
                        
                        <div className='main_purchases-card__content-purchase'>                      
                            { 
                                !purchases.result
                                    ? <p>No se encontraron resultados</p>
                                    : purchases.result.map((item, index) => (
                                        <PurchasesRows key={index} purchase={item} />
                                    ))
                            }
                        </div>
                    </div>                 
                </div>
            </div>

            <Footer/>
        </>
        
    );
}

function PurchasesRows(props) {

    const { purchase } = props;

    return (
        <div className='main_purchases-card__content-purchase__row'>
            <div className='main_purchases-card__content-purchase__row-col'>
                {purchase.id}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {purchase.plan}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {purchase.moneda + purchase.costo}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {purchase.cantidad}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {purchase.moneda + (purchase.costo * purchase.cantidad)}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {purchase.compra.transaccion}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {new Intl.DateTimeFormat('es-CR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(Date.parse(purchase.compra.fechaCompra)).setHours(new Date(Date.parse(purchase.compra.fechaCompra)).getHours() - 6))}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {new Intl.DateTimeFormat('es-CR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(Date.parse(purchase.compra.ultimoPago)).setHours(new Date(Date.parse(purchase.compra.ultimoPago)).getHours() - 6))}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {new Intl.DateTimeFormat('es-CR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(Date.parse(purchase.compra.proximoPago)).setHours(new Date(Date.parse(purchase.compra.proximoPago)).getHours() - 6))}
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {
                    purchase.compra.pagoAutomatico == true
                    ? 'Sí'
                    : 'No'
                }
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {
                    purchase.compra.requiereFactura == true
                    ? 'Sí'
                    : 'No'
                }
            </div>
            <div className='main_purchases-card__content-purchase__row-col'>
                {purchase.compra.card}
            </div>
            {/* <div className='main-card__content-purchase__row-col'>
                <Button>Gestionar</Button>
            </div> */}
        </div>
    );
}