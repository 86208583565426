import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import WhatsappImg from '../../assets/img/logos/Whatsapp.png';

import './whatsapp.scss';

export default function Whatsapp() {
    return (
        <div className='floating'>
            <div className='floating-container'>
                <a target={'_blank'} href='https://api.whatsapp.com/send/?phone=50664791168&text&type=phone_number&app_absent=0'>
                    <Button><img src={WhatsappImg}/>Chat</Button>
                </a>
            </div>
        </div>
    );
}