import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import UseFetchSimple from '../../hooks/use_fetch_simple';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../utils/constants';

import './form_contact.scss';

export default function FormContact() {

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [bussiness, setBussiness] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();

        const response = UseFetchSimple(URL_API_AURA + '/ContactMail?clientSecret=' + FINAL_KEY_API_AURA,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(
                    {
                        nombre: name,
                        apellido: lastname,
                        empresa: company,
                        correo: email,
                        cantidad: quantity,
                        negocio: bussiness
                    }
                )
            }
        );

        toast.success(`Formulario enviado correctamente`);

        setName('');
        setLastname('');
        setCompany('');
        setEmail('');
        setQuantity(0);
        setBussiness('');
    }

    return (
        <div className='form-contact'>
            <h4>Llena nuestro formulario para recibir una cotización personalizada</h4>

            <Form onSubmit={onSubmit} className="form-contact__send">
                <label>Nombre</label>
                <input type="text" placeholder="Nombre" required onChange={(e) => setName(e.target.value)} value={name}/>
                <label>Apellido</label>
                <input type="text" placeholder="Apellido" required onChange={(e) => setLastname(e.target.value)} value={lastname}/>
                <label>Nombre de la empresa</label>
                <input type="text" placeholder="Nombre de la empresa" required onChange={(e) => setCompany(e.target.value)} value={company}/>
                <label>Correo electrónico corporativo</label>
                <input type="email" placeholder="Correo electrónico corporativo" required onChange={(e) => setEmail(e.target.value)} value={email}/>
                <label>Cantidad colaboradores</label>
                <input type="number" placeholder="Cantidad colaboradores" required onChange={(e) => setQuantity(e.target.value)} value={quantity}/>
                <label>Giro de negocio</label>
                <input type="text" placeholder="Giro de negocio" required onChange={(e) => setBussiness(e.target.value)} value={bussiness}/>
                
                <Button type="submit">
                    Enviar
                </Button>
            </Form>
        </div>
    );
}
