import React from 'react';
import { Spinner } from 'react-bootstrap';

import './processing.scss';

export default function Processing() {
    return (
        <div className='processing'>
            <Spinner animation='border' role='status' />
            <h5>Procesando... Por favor espere</h5>
        </div>
    );
}