import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { URL_API_AURA, FINAL_KEY_API_AURA } from '../../../../utils/constants';
import { b64EncodeUnicode } from '../../../../utils/arrayFunc';

import Loading from '../../../../components/loading';

import '../../account.scss';

export default function Login(props) {

    const { login } = props;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if(result.identificador == '0')
        {
            const lastPath = localStorage.getItem('lpi_acr') || '/';

            login({
                ssul: true,
                uli: b64EncodeUnicode(username)
            });

            navigate(lastPath, {
            replace: true 
            });
        }
        else if(result.identificador == '1' || result.identificador == '2')
        {
            setError('* ' + result.message);
            setLoading(false);
        }
    }, [result]);

    const onLogin = async (e) => {
        e.preventDefault();

        setError('');
        setLoading(true);

        try {
            const res = await fetch(URL_API_AURA + '/Login?username=' + b64EncodeUnicode(username) + 
                '&password=' + b64EncodeUnicode(password) + 
                '&clientSecret=' + FINAL_KEY_API_AURA,
                {
                    method: 'POST'
                }
            );

            if(res.status == '200')
            {
                const json = await res.json();
                setResult(json);
            }
            else
            {
                setError('* Ocurrió un error al intentar iniciar sesión.');
                setLoading(false);
            }                         
        }
        catch (e)
        {
            setError(e);
            setLoading(false);
        }
        finally
        {
            setLoading(false); 
        }
    }

    return (
        <>
            <Row>
                <Col xs={1} lg={3}></Col>
                
                <Col xs={12} lg={6}>
                    <div className="main">
                        <div className='main-card'>
                            <h3>Iniciar Sesión</h3>

                            {
                                loading == true
                                    ?   <Loading/>
                                    :   <>
                                            <Form onSubmit={onLogin} className="main-card__form">
                                                <label>Correo Electrónico</label>
                                                <input type="email" placeholder="Correo electrónico" required onChange={(e) => setUsername(e.target.value)} value={username}/>
                                                <label>Contraseña</label>
                                                <input type="password" placeholder="Contraseña" required onChange={(e) => setPassword(e.target.value)} value={password}/>
                
                                                <Button type="submit">
                                                    Entrar
                                                </Button>
                                            </Form>

                                            <div className="main-card__options">
                                                <Link to='/Account'>Crear cuenta</Link>
                                                <Link to='/ForgotPassword'>Olvidé Contraseña</Link>
                                            </div> 
                                        </>
                            }
                            
                            <p className="main-card__error">{error}</p>                                                  
                        </div>             
                    </div>
                </Col>
                
                <Col xs={1} lg={3}></Col>
            </Row>           
        </>
    );
}
