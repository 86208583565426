import { b64EncodeUnicode } from '../utils/arrayFunc';

export const STORAGE_PRODUCTS_CART = 'pc_acr';

//AURA
export const URL_IMG_AURA = 'https://botgevlc43usy.blob.core.windows.net/aurafiles/ImagenesAura';
export const KEY_API_AURA = 'fb7e6bef91c252f022a2ad8ce211157e3354d752';
export const FINAL_KEY_API_AURA = b64EncodeUnicode(KEY_API_AURA);
// export const URL_API_AURA = 'https://localhost:44381/api/Api';
export const URL_API_AURA = 'https://garrettaurawebapp.azurewebsites.net/api/Api';

//BAC
// export const KEY_API_BAC = 'QnDYtrVvluDc';
// export const Merchant = '111379';
// export const Terminal = '111379001';
// export const URL_API = 'https://tokenv2test.merchantprocess.net/TokenWebService.asmx?wsdl';
// export const URL_WIDGET = 'https://bacapicomponent.test.merchantprocess.net/UIComponent/CreditCard';

export const KEY_API_BAC = 'ZjGvEpSCwDZn';
export const Merchant = '102949';
export const Terminal = '102949002';
export const URL_API = 'https://gateway.merchantprocess.net/tokenv2/TokenWebService.asmx?wsdl';
export const URL_WIDGET = 'https://bacgateway.merchantprocess.net/SecureComponent/UIComponent/CreditCard';