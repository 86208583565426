import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import Aura from '../../assets/img/logos/LogoAzul.png';
import WhatsappImg from '../../assets/img/logos/Whatsapp.png';
import Facebook from '../../assets/img/logos/Facebook.png';
import Instagram from '../../assets/img/logos/Instagram.png';
import Waze from '../../assets/img/logos/Waze.png';
import Maps from '../../assets/img/logos/Maps.png';

import './footer.scss';

export default function Footer() {
    const { Footer } = Layout;

    return (
        <Footer className='footer' bg='light'>
            <Container>
                <Row className='footer-row'>
                    <Col xs={12} lg={4} className='footer-row_col1'>
                        <img src={Aura} alt='icon'/>
                        <p>+(506) 6479-1168</p>
                        <p>info@aura-cr.com</p>
                        {/* <p>¡Síguenos!</p> */}
                        <div className='footer-row_col1-redesImg'>
                            <a rel="noopener noreferrer" target='_blank' href='https://api.whatsapp.com/send/?phone=50664791168&text&type=phone_number&app_absent=0'><img src={WhatsappImg} alt='whatsapp'/></a>
                            <a rel="noopener noreferrer" target='_blank' href='https://www.facebook.com/aurabienestarcr'><img src={Facebook} alt='facebook'/></a>
                            <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/auraesbienestar'><img src={Instagram} alt='instagram'/></a>
                            <a rel="noopener noreferrer" target='_blank' href='https://ul.waze.com/ul?ll=9.93469443%2C-84.09296036&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'><img src={Waze} alt='waze'/></a>
                            <a rel="noopener noreferrer" target='_blank' href='https://goo.gl/maps/tXgTcVA6eEKKn4jm7'><img src={Maps} alt='maps'/></a>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className='footer-row_col2'>
                        <h6>¿Qué es Aura?</h6>
                        <Link to='/Membership'><p>Beneficios</p></Link>
                        <p>Testimonios</p>
                        <p>Términos y Condiciones</p>
                    </Col>
                    <Col xs={12} lg={4} className='footer-row_col3'>
                        <h6>Precios de Planes</h6>
                        <Link to='/Empresa'><p>Para tu empresa</p></Link>
                        <Link to='/Planes'><p>Comprar ahora</p></Link>
                        {/* <a href='/Planes'><p>Comprar ahora</p></a> */}
                    </Col>
                </Row>
                <p>Copyright © 2023 Aura - Planes de Bienestar a tu Alcance</p>
            </Container>
        </Footer>
    );
}
