import { Navigate, useLocation } from 'react-router-dom';

export default function RoutePrivate(props) {

    const { user, route } = props;

    const { pathname } = useLocation();

    localStorage.setItem('lpi_acr', user ? '/' : pathname);
    
    return (user)
        ? route
        : <Navigate to='/Login'/>
}